import React from 'react';
import {Link} from 'react-router-dom';

import './Button.css';

const Button = props => {
    if (props.href) {
        return (
            <a
                className={`button ${props.small && 'font__small'} ${props.textRed && 
                  'button__text__primary'} ${props.long && 'button__long'} ${props.white && 'bg-white text-black'} ${props.nopadding && 'px-0 py-0'} ${props.colored && 'bg-black text-white'}`}
                href={props.href}>
                {props.children}
            </a>
        );
    }
    if (props.to) {
        return (
            <Link
                to={props.to}
                exact={props.exact}
                className={`button ${props.long && 'button__long'} ${props.textRed && 
                'bg-white button__text__primary'} ${props.transparent && 
                    'button__text__primary bg-transparent'} ${props.textBlack && 
                        'bg-white text-black '} ${props.nopadding && 
                'px-0 py-0'} ${props.padding && 'button__padding'} 
                ${props.textWhite && 'text-white'} ${props.marginright && 'mr-2'} 
                ${props.colored && ' text-white button__big'} ${props.ButtonClassName}`}>
                {props.children}
            </Link>
        );
    }
    return (
        <button
            className={`button button--${props.size || 'default'} ${props.inverse && 'button--inverse'} ${props.danger && 'button--danger'} py-2 px-4 shadow-md`}
            type={props.type}
            onClick={props.onClick}
            disabled={props.disabled}>
            {props.children}
        </button>
    );
};

export default Button;
