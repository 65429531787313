import React from 'react'

function PolicyItem(props) {
    return (
        <div className='w-full md:w-4/5 xl:w-3/4 h-96 relative' >
            <div className='w-full h-96' style={{background:`linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.0001) 26.89%, #000000 104.06%)`}}>
                <img className='relative w-full h-full object-cover -z-10' src={props.src} alt={props.alt}  />
            </div>
            <p className='absolute text-base bottom-8 left-4 text-white'>{props.title}</p>
        </div>
    )
}

export default PolicyItem
