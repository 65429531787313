import React, {useContext, useState, useEffect} from 'react'
import { Route, Routes, Navigate } from "react-router-dom";


import Sidebar from './Sidebar/Sidebar';

import AdminNavbar from '../../default/header/Dashboard/Navbars/AdminNavbar';

import Insurances from './Sections/Insurances';
import InsurancesItem from './Components/InsurancesItem'

import InsurancesPolicies from './Sections/InsurancesPolicies';
import PolicyInsurers from './Components/PoliciesSections/PolicyInsurers';
import Settings from './Sections/Settings';
import Claims from './Sections/Claims';
import ClaimsItem from './Components/Claims/ClaimsItem'
import Payments from './Sections/Payments';


import {AuthContext} from '../../context/auth-context';
import {DataContext} from './context/DataContext';
import { useHttpClient } from './hooks/dashboard-http-hook';

import './dashboard.css'
import Loader from './UI_Elements/Loader/Loader'


function Dashboard() {
    const auth = useContext(AuthContext);  

    const[firstname,  setFirstName] = useState("")
    const[lastname,  setLastName] = useState("")
    const[email,  setEmail] = useState("")
    const[image,  setImage] = useState(null)
    const[phoneNumber,  setPhoneNumber] = useState(null)

    function dataCollection(firstname, lastname, email,phonenumber, image){
        if(image){
            return setImage(image)
        }

        setFirstName(firstname);
        setLastName(lastname);
        setEmail(email);
        setPhoneNumber(phonenumber);
        

    }

    const {isLoading, error, sendRequest} = useHttpClient();

    useEffect(() => {
        const fetchData = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/getData`, "GET", null, {Authorization: 'Bearer '+auth.token});

                setFirstName(responseData.customer.firstname);
                setLastName(responseData.customer.lastname);
                setEmail(responseData.customer.email)
                setPhoneNumber(responseData.customer.phone_number);
                setImage(responseData.customer.image);

            } catch (err) {
                console.log(error)
            }
        };
        fetchData();
    }, [sendRequest, error, auth]); 

    return (
        <>   
        {isLoading ? <Loader/> : 
            <DataContext.Provider 
            value={{
                firstname:firstname,
                lastname:lastname,
                email:email,
                phonenumber:phoneNumber,
                image:image,
                UpdateData:dataCollection
            }}>
           
            <Sidebar/>
            <div className="relative md:ml-48 xl:ml-64 dashboard__background ">
                <AdminNavbar />
                <div className="px-4 mx-auto w-full mt-8 dashboard__background dashboard__height" >
                <Routes>
                    <Route path='insurances' exact element={<Insurances/>} />
                    <Route path="insurances/:id" exact element={<InsurancesItem/>}/>
                    <Route path='policies' exact element={<InsurancesPolicies/>} />
                    <Route path='policies/:id' exact element={<PolicyInsurers/>} />
                    <Route path='payments' exact element={<Payments/>} />
                    <Route path='claims' exact element={<Claims/>} />
                    <Route path='claims/:id' exact element={<ClaimsItem/>}/>
                    <Route path='settings' exact element={<Settings/>} />
                    <Route path="*" element={<Navigate to ="insurances" />}/>
                </Routes>
                </div>

        </div>

        </DataContext.Provider>
        }
            
        </>
    )
}

export default Dashboard