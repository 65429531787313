import Marine from './Marine'
import OilEnergy from './OilEnergy'
import Motor from './Motor'
import Fire from './Fire'
import ErectionRisks from './ErectionRisks'


const exportedObject = {
    Motor:Motor,
    OilEnergy,
    Marine,
    Fire,
    ErectionRisks
}


export default exportedObject