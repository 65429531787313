import React from 'react'

import Input from '../UI_Elements/Input/Input'
import Loader from '../UI_Elements/loader/Loader';


import {useForm} from '../hooks/form-hook'
import {useHttpClient} from '../hooks/http-hook';


import * as Icons from "react-icons/io";

import './auth.css'

import {VALIDATOR_EMAIL} from '../util/validators'
import {useNavigate} from 'react-router-dom';

import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import Backdrop from '../UI_Elements/Backdrop/Backdrop';

function ResetModalOverlay(props) {
    const history = useNavigate();

    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
        inputHandler] = useForm({

        email: {
            value: '',
            isValid: false
        }
    }, false);

    const SubmitHandler = async event => {
        event.preventDefault();

        try {

            const formData = new FormData();
            formData.append("email", formState.inputs.email.value)

            await
            sendRequest(`${process.env.REACT_APP_API_CALL}/customers/password/email`, 'POST', formData)
            
            history("/login")

        } catch (err) {

            console.log(error)
        }
    };


    const content = (
        <div className='p-8 md:p-24 modal'>
            <div className='flex flex-col justify-center items-center '>
                <h1 className='text-lg lg:text-2xl font-bold text-center'>Reset Password</h1>
                <p className='text-sm md:text-base opacity-50 mt-8 text-center'>Enter the email address associated with your account and check for instructions</p>
                <form className='w-full md:w-4/5 mt-8' onSubmit={SubmitHandler}>
                    <div className='grid grid-cols-1'>

                        <Input
                            type="email"
                            placeholder='sampleemail@gmail.com'
                            label="Email Address"
                            id="email"
                            validators={[VALIDATOR_EMAIL()]}
                            errorText="Please enter a valid email"
                            onInput={inputHandler}/>

                    </div>

                    <div className='mt-8 flex justify-center items-center flex-wrap'>
                        <input
                            type='submit'
                            className={`form__submit w-full button__padding text-white ${isLoading && 'mr-2'} `}
                            value="Send Instructions"/> {isLoading && <Loader/>}
                    </div>

                </form>
            </div>

            <div className='absolute top-4 right-4'>
            <button onClick={props.onCancel}>
                <Icons.IoIosClose className='text-base text-red-700 bg-red-300 rounded pointer' />
            </button>
            </div>

        </div>
    )

    return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const ResetModal = props => {
    return (
      <React.Fragment>
        {props.show && <Backdrop onClick={props.onCancel} />}
        <CSSTransition
          in={props.show}
          mountOnEnter
          unmountOnExit
          timeout={200}
          classNames="modal"
        >
          <ResetModalOverlay {...props} />
        </CSSTransition>
      </React.Fragment>
    );
  };


export default ResetModal
