import React, { useEffect, useMemo, useState, useCallback } from 'react'

// util components
import { VALIDATOR_MAXLENGTH, VALIDATOR_REQUIRE } from '../../../../util/validators';
import { useForm } from '../../../../hooks/form-hook';

import { vehicleMake, vehicleTypes, vehicleYears } from '../../util/policyForm/vehicle';

// custom components
import Input from '../../../../UI_Elements/Input/Input';
import Select from '../../UI_Elements/Select/Select';
import ImageCollectionItem from '../components/ImageCollectionItem';
import Number from '../../../../UI_Elements/Input/Number';


function FormTwo(props) {

    const [useOfVehicle, setUseOfVehicle] = useState('')

    const [uploadImages, setUploadImages] = useState([])

    const [typeOfCover, settypeOfCover] = useState('')

    const [vehicleMakeValue, setVehicleMakeValue] = useState('')

    const [vehicleTypesValue, setVehicleTypesValue] = useState('')

    const [vehicleModelValue, setVehicleModelValue] = useState('')

    const [formState,
        inputHandler] = useForm({
        vehicleColor: {
            value: '',
            isValid: false
        },
        registrationNumber: {
            value: '',
            isValid: false
        },
        chasisNumber: {
            value: '',
            isValid: false
        },
        engineNumber: {
            value: '',
            isValid: true
        },
        valueOfAsset: {
            value: '',
            isValid: true
        }
    }, false);

    

    const vehicleData = useMemo(() => {
        const vehicle = {
            vehicleMake: vehicleMakeValue,
            vehicleTypes: vehicleTypesValue,
            vehicleModel: vehicleModelValue.toString(),
            vehicleColor: formState.inputs.vehicleColor.value,
            registrationNumber: formState.inputs.registrationNumber.value,
            chasisNumber: formState.inputs.chasisNumber.value,
            engineNumber: formState.inputs.engineNumber.value,
            valueOfAsset: formState.inputs.valueOfAsset.value,
            useOfVehicle: useOfVehicle,
            uploadImages:uploadImages, 
            typeOfCover:typeOfCover
        }
        return vehicle
    }, [formState, useOfVehicle, uploadImages, typeOfCover, vehicleMakeValue, vehicleTypesValue, vehicleModelValue])

    
    const {input} = props

    useEffect(() => {
        input(vehicleData)
    }, [input, vehicleData])


    const useOfVehicleHandler = useCallback((value) => {
        setUseOfVehicle(value)
    }, [])

    const imagesHandler = useCallback( (files) => {
        setUploadImages(files)
    }, [])

    const valueofAssetHandler = (state) =>{
        formState.inputs.valueOfAsset.value = state
    }
    
    const typeOfCoverHandler = useCallback((value) => {
        settypeOfCover(value)
    }, [])

    const makeVehicleHandler = useCallback((value) => {
        setVehicleMakeValue(value)
    }, [])
    

    const typesVehicleHandler = useCallback((value) => {
        setVehicleTypesValue(value)
    }, [])

    const modelVehicleHandler = useCallback((value) => {
        console.log(value)
        setVehicleModelValue(value)
    }, [])


  return (

        <div className='grid grid-cols-2 gap-8'>

            <Select 
                options={vehicleMake}
                label="Make of Vehicle"
                id="vehicleMake"
                search
                initialValue={props.vehicleMake || ""}
                placeholder='Select make of vehicle'
                onInput={makeVehicleHandler}/>

            <Select 
                options={vehicleTypes}
                label="Type of Vehicle"
                id="vehicletypes"
                search
                initialValue={props.vehicleTypes || ""}
                placeholder='Select vehicle type'
                onInput={typesVehicleHandler}/>


            <Select 
                options={vehicleYears}
                label="Vehicle Model/Year"
                id="vehicleModel"
                search
                initialValue={props.vehicleModel || ""}
                placeholder='Select vehicle model'
                onInput={modelVehicleHandler}/>


            <Input
                type="text"
                placeholder='Vehicle Color?'
                label="Vehicle Color"
                id="vehicleColor"
                initialValue={props.vehicleColor || ""}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please this field is required"
                onInput={inputHandler} 
                />

            <Input
                type="text"
                placeholder='Registration Number'
                label="Registration Number"
                id="registrationNumber"
                initialValue={props.registrationNumber || ""}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please this field is required"
                onInput={inputHandler} 
                />

            <Input
                type="text"
                placeholder='i.e JN3MS37A9PW202929'
                label="Chasis Number"
                id="chasisNumber"
                initialValue={props.chasisNumber || ""}
                validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(17)]}
                errorText="Please this field length is invalid"
                onInput={inputHandler} 
                />

            <Input
                type="text"
                placeholder='example: 52WVC10338'
                label="Engine Number (optional)"
                id="engineNumber"
                initialValue={props.engineNumber || ""}
                validators={[VALIDATOR_MAXLENGTH(17)]}
                errorText="Please this field length is invalid"
                onInput={inputHandler} 
                />

                <Number 
                    label="Value of Asset" 
                    id="valueofAsset"
                    className="border-2 p-2 rounded-sm border-slate-300 bg-white input_button" 
                    onInput={valueofAssetHandler}/>

            <div className='col-span-2'>
                <h1 className="pb-2 dash__color w-full dash_border-b border-black mb-4 xl:mb-8">Images of Vehicle</h1>
                <ImageCollectionItem className="w-full col-span-2" multiple={true} item images={props.uploadImages !== undefined ? props.uploadImages : []} accept="image/*" browse="(jpg, png, jpeg)" onFiles={imagesHandler}/>
            </div>
            
            <Select 
                options={['Private','Commercial']}
                label="Use of Vehicle"
                id="useOfVehicle"
                initialValue={props.useOfVehicle || ""}
                placeholder='Use of Vehicle'
                onInput={useOfVehicleHandler}/>

            <Select 
                options={['Comprehensive', 'Third Party']}
                label="Type of Cover"
                id="typeOfCover"
                initialValue={props.typeOfCover || ""}
                placeholder='Type of Cover'
                onInput={typeOfCoverHandler}/>

        </div>
  )
}

export default FormTwo