import React, { useEffect, useContext, useState, useCallback } from 'react'

import { Link, useNavigate } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import moment from 'moment'

import {AuthContext} from '../../../context/auth-context';
import * as IconT from 'react-icons/io';

import StateButton from '../../../UI_Elements/Button/StateButton';

import Backdrop from '../../../UI_Elements/Backdrop/Backdrop'

import {useHttpClient} from '../hooks/dashboard-http-hook'
import CarInsuranceItem from './Insurance/CarInsuranceItem';

function InsurancesItem() {
    const {id} = useParams();
    const auth = useContext(AuthContext)

    const history = useNavigate()

    const [insuranceData, setInsuranceData] = useState(null);

    const [objectInsuranceData, setObjectInsuranceData] = useState(null);

    const {error, sendRequest} = useHttpClient();


    useEffect(() => {


        const fetchData = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/insurance/list/${id}`, "GET",null, {Authorization: 'Bearer '+auth.token});
                setInsuranceData(responseData.insurance)
               

                if(responseData.insurance.carinsurenace !== null){
                    setObjectInsuranceData(responseData.insurance.carinsurenace)
                }
                
                


            } catch (err) {
                console.log(error)
            }
        };
        fetchData();

    }, [sendRequest, error, auth, id]);


    const singleClaim = useCallback((e, id) =>{
        e.preventDefault()
        history(`../claims/${id}`)
    },[history])
    
    return (

            <>

        {insuranceData === null  ? <Backdrop/> : (
            <>
            <div className='flex flex-col md:flex-row justify-between items-center'>
                <Link to="../insurances" className="flex justify-center items-center">
                    <IconT.IoIosArrowDropleftCircle className="dash_action_button text-base mr-2"/>
                    <p className="dash__color text-base font-normal">All My Insurances</p>
                </Link>

                {
                   moment(insuranceData.attached_policy.company.created_at).add(1, 'years').format('lll') >= moment(new Date()).format("lll")? (
                    <div className='flex flex-col md:flex-row justify-center items-center mt-4 md:mt-0'>
                        
                        <button className='py-2 px-12 dash_button_red text-sm mt-2 md:mt-0'>Re-Insure</button>
                </div>
                   ) : (
                    <div className='flex flex-col md:flex-row justify-center items-center mt-4 md:mt-0'>
                        
                        <button className='py-2 px-12 dash_button_disabled text-sm mt-2 md:mt-0'>Re-Insure</button>
                </div>
                   )
                }
                

            </div>

            
            <div className="mt-8 grid grid-cols-1 gap-4 md:gap-8">
                <div className='md:col-span-3 px-4 py-2 pb-16 rounded-xl shadow bg-white'>
                    <h1 className="pb-2 dash__color w-full dash_border-b border-black">Insurance Info</h1>

                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2'>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Policy</h3>
                            <p className='dash__text text-left text-sm'>{insuranceData.policy.policy_name}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Insurer</h3>
                            <p className='dash__text text-left text-sm'>{insuranceData.attached_policy.company.company_name}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Value of Asset</h3>
                            <p className='dash__text text-left text-sm'>₦{insuranceData.value_of_assets}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Rate</h3>
                            <p className='dash__text text-left text-sm'>{insuranceData.attached_policy.rate}%</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Premium</h3>
                            <p className='dash__text text-left text-sm'>₦{insuranceData.premium}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Start Date</h3>
                            <p className='dash__text text-left text-sm'>{moment(insuranceData.created_at).format('lll')}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Expiry Date</h3>
                            <p className='dash__text text-left text-sm'>{moment(insuranceData.created_at).add(1, 'years').format('lll')}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-1'>Status</h3>
                            {/* <button className='dash_button_active px-4 py-2 rounded-full text-center text-sm'>ACTIVE</button> */}
                            { insuranceData.status === "1" && <StateButton  neutral>
                                PENDING
                            </StateButton>}
                            { insuranceData.status === "2" && <StateButton>
                                ACTIVE
                            </StateButton>}
                            {insuranceData.status === "3" && <StateButton expired>
                            EXPIRED
                            </StateButton>}
                        </div>
                    </div>
                </div>

                {objectInsuranceData !== null && <CarInsuranceItem 
                vehicleMake={objectInsuranceData.vehicle_make} 
                vehicleType={`${objectInsuranceData.vehicle_type} ${objectInsuranceData.vehicle_model}`}
                vehicleColor={objectInsuranceData.vehicle_color}
                registrationNumber={objectInsuranceData.vehicle_registration_number}
                chasisNumber={objectInsuranceData.vehicle_chassis_number} 
                engineNumber={objectInsuranceData.vehicle_engine_number === null ? "" : objectInsuranceData.vehicle_engine_number} 
                useOfVehicle={objectInsuranceData.use_of_vehicle}
                typeOfCover={objectInsuranceData.type_of_cover} 
                photo_of_vehicle ={objectInsuranceData.photo_of_vehicle}
                drivers_license ={objectInsuranceData.drivers_license}/>
                }

                <div className='md:col-span-3 px-4 py-2 pb-8 md:pb-16 overflow-x-auto'>
                    <h1 className='text-base text-primary-black mb-4 '>Claims</h1>
                    <table className='rounded-md shadow overflow-x-auto bg-white'>
                        <thead>
                            <tr >
                                <th className='rounded-tl-md text-xs'>Claim ID</th>
                                <th className="text-xs whitespace-nowrap">Circumstance</th>
                                <th className="text-xs whitespace-nowrap">Date</th>
                                <th className="text-xs whitespace-nowrap">Status</th>
                                <th className='px-8 py-2 rounded-tr-lg'></th>
                            </tr>
                        </thead>

                        <tbody className="relative">

                            {
                                insuranceData.claim.length === 0 ? (<h1 className="text-center absolute top-1/2 left-4">No Claims Issued At The Moment</h1>) :
                                insuranceData.claim.map((claim, index) =>{
                                    return(
                                        <tr key={index}>
                                            <td className='text-sm whitespace-nowrap'>{claim.claim_id}</td>
                                            <td className="text-sm whitespace-nowrap">{claim.circumstance}</td>
                                            <td className="text-sm whitespace-nowrap">{moment(claim.created_at).format('lll')}</td>
                                            <td>
                                                {claim.status === "0" ? (<StateButton neutral>
                                                    PENDING
                                                </StateButton>) : claim.status === "1" ? (<StateButton active>
                                                    ACTIVE
                                                </StateButton>) : (<StateButton expired>
                                                    DECLINED
                                                </StateButton>)}
                                            </td>
                                            <td className='py-1 px-4 m-2 bg-transparent cursor-pointer' onClick={(e) => singleClaim(e,claim.claim_id)}><IconT.IoIosArrowDroprightCircle className="dash_action_button text-base"/></td>
                                        </tr>
                                    )
                                })
                            }

                            
                        </tbody>
                    </table>
                </div>

            </div>
            </>)}
        </> 
        
    )
}

export default InsurancesItem
