import React, { useState } from 'react';

import * as Icons from 'react-icons/io'

import ClaimsTable from '../Components/Claims/ClaimsTable'
import ClaimsDetails from '../Components/Claims/ClaimsDetails'

function Claims() {
    const [edit, setEdit] = useState(false)

    const editHandler = (e) =>{
        e.preventDefault();

        setEdit(!edit);

    }

    const onClaims = (value) =>{
        setEdit(value)
    }
  return (
      <>
        {!edit ? (
            <>
            <div className="flex justify-between items-center">
            <h1 className="text-primary-black text-lg font-normal">All Claims</h1>
            <button className='dash_button_red px-8 py-2 rounded dash_button_pointer' onClick={editHandler}>New Claim</button>
            </div>

            <ClaimsTable/>
            </>
        ): (
                <>
                <button className=' flex justify-start items-center rounded dash_button_pointer' onClick={editHandler}>
                    <Icons.IoIosArrowDropleftCircle className='dash_action_button text-base mr-2'/>
                    <p className='dash__color'>All Claims</p>
                </button>
                <ClaimsDetails onEdit={onClaims} />
                </>
        )}
        
      </>
      
  );
}


export default Claims;