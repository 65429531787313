import React, { useState, useEffect, useRef } from 'react';
import { policyItems } from './policyItems';
import { Link } from 'react-router-dom';

function PolicyNavs() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const containerRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && 
    containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={containerRef}>
      <button
        id="dropdownNavbarLink"
        data-dropdown-toggle="dropdownNavbar"
        className="text-gray-700  hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 pl-3 pr-4 py-2 md:hover:text-primary-red md:p-0 md:pl-6 font-medium flex items-center justify-between w-full md:w-auto"
        onClick={toggleDropdown}
      >
        Policy{' '}
        <svg
          className="w-4 h-4 ml-1"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      {/* Dropdown menu */}
      {isOpen && (
        <div
          ref={dropdownRef}
          id="dropdownNavbar"
          className="absolute right-0 bg-white text-base z-10 list-none  rounded shadow my-4 w-80"
        >
          <ul className="py-1" aria-labelledby="dropdownLargeButton">

            {policyItems.map((item) => {
                return (
                    <li>
                    <Link
                        to={item.path}
                        className="text-sm font-light hover:text-primary-red text-primary-black block px-4 py-2"
                    >
                        {item.title}
                    </Link>
                    </li>
                )
            })}
            
            
            
          </ul>
          
        </div>
      )}
    </div>
  );
}

export default PolicyNavs;
