import React from 'react'

function StarCard(props) {
    const {text, image} = props
  return (
    <div className="flex justify-center flex-col items-center">
        <img src={image} alt={`star`}/>
        <h2 className='uppercase mt-3 text-xl text-primary-black font-bold'>
            {text}
        </h2>
    </div>
  )
}

export default StarCard