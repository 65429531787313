import {images} from '../../../assests/index'

export const policyItems = [
    {
        title:'Fire Insurance',
        id:1,
        src:images.fireInsurance[0],
        alt:images.fireInsurance[1]
    },
    {
        title:'Machinery Breakdown Insurance',
        id:2,
        src:images.machineryInsurance[0],
        alt:images.machineryInsurance[1]
    },
    {
        title:'Motor Insurance',
        id:3,
        src:images.motorInsurance[0],
        alt:images.motorInsurance[1]
    },
    {
        title:'Personal Accident Insurance',
        id:4,
        src:images.personalInsurance[0],
        alt:images.personalInsurance[1]
    },
    {
        title:'Burglary Insurance',
        id:5,
        src:images.burglaryInsurance[0],
        alt:images.burglaryInsurance[1]
    },
    
]
