import React from 'react'

//default components
import Header from '../../default/header/Header'
import Footer from '../../default/footer/landing/Footer'

import { Route, Routes } from 'react-router-dom'


import './landing.css'
import Home from './Sections/Home/Home'
import About from './Sections/About/About'
import index from './Sections/Policies/index'


function Landing() {
    return (
        <>
            <Header/>
                <Routes>
                    <Route path='/' element={<Home/>} />
                    <Route path='/about' element={<About/>} />
                    <Route path='/marine' element={<index.Marine/>} />
                    <Route path='/oil-energy' element={<index.OilEnergy/>} />
                    <Route path='/motor' element={<index.Motor/>} />
                    <Route path='/fire-extraneous' element={<index.Fire/>} />
                    <Route path='/erection-all-risks' element={<index.ErectionRisks/>} />
                    {/* <Route path='/policies' element={<About/>} /> */}
                    <Route path='*' element={<Home/>} />
                </Routes>
            
            <Footer/>
        </>
    )
}

export default Landing
