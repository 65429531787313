
export const boardItems = [
    {
        image: 'https://res.cloudinary.com/devgodfrey/image/upload/v1688355822/m-kebir.png',
        text: 'M-Kebir Yakubu, B.'
    },
    {
        image: 'https://res.cloudinary.com/devgodfrey/image/upload/v1688355822/gregory.png',
        text: 'Gregory Utomwen'
    },
    {
        image: 'https://res.cloudinary.com/devgodfrey/image/upload/v1688355823/emmanuel-owojo.png',
        text: 'Emmanuel Owojo Haruna'
    },
    {
        image: 'https://res.cloudinary.com/devgodfrey/image/upload/v1688355822/ayedun-fashina.png',
        text: 'Ayedun Fasina'
    }
]
