import React from 'react'

import { saveAs } from "file-saver";

import * as Icons from "react-icons/io";
import * as IconT from "react-icons/hi";

import { images } from '../../../../assests';

import ReactDOM from 'react-dom';
import {CSSTransition} from 'react-transition-group';
import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop';

function DetailsModalOverlay(props) {

    const saveFile = () => {
        saveAs(
          props.file,
          props.title
        );
      };

    const content = (
        <div className='p-8 md:p-18 md:py-20 modal'>
            <div className='flex flex-col justify-center items-center '>
                <h1 className='text-lg lg:text-2xl font-bold text-center'>{props.title}</h1>
                <p className='text-sm md:text-base opacity-50 mt-8 text-center'>{props.text}</p>
                
                    <button onClick={saveFile} className='my-8 p-4 md:p-8 bg-white shadow-md rounded-md'>
                        <div className="flex justify-center items-center">
                            <img src={images.AdobeDownload[0]} alt={images.AdobeDownload[1]} className='w-18 h-18'/>
                            <div className="flex justify-center items-start flex-col mx-4">
                                <h3 className='text-base font-bold'>{props.title}.{props.ext}</h3>
                                <p className='text-sm'>{props.fileSize}MB</p>
                            </div>
                            <IconT.HiArrowNarrowDown className={`text-primary-red `}/>
                        </div>
                    </button>
                
                <button
                    className='form__submit py-3 px-8 md:px-16 lg:px-24 text-white mx-auto '
                    onClick={props.onClick}>Buy Insurance</button>
            </div>

            <div className='absolute top-4 right-4'>
                <button onClick={props.onCancel}>
                    <Icons.IoIosClose
                        className='text-base text-red-700 bg-red-300 rounded pointer'/>
                </button>
            </div>

        </div>
    )

    return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const DetailsModal = props => {
    return (
        <React.Fragment>
            {props.show && <Backdrop onClick={props.onCancel}/>}
            <CSSTransition
                in={props.show}
                mountOnEnter
                unmountOnExit
                timeout={200}
                classNames="modal">
                <DetailsModalOverlay {...props}/>
            </CSSTransition>
        </React.Fragment>
    );
};

export default DetailsModal
