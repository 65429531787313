import React from 'react';

function Profile(props) {
    return (
        <div
            className={`${props.profileClass} `}>
            <p className={`text-2xl uppercase my-auto ${props.red? 'text-primary-red' : 'text-white'} ${props.textCenter? 'text-center' : ''} `}>{props
                    .name
                    .split('')[0]}</p>
        </div>
    )
}

export default Profile;
