import React from 'react'
import TitleHOC from './TitleHOC'
import ItemLi from './ItemLi'

function OilEnergy() {
  return (
    <div>
        <div className="mb-8">
            <TitleHOC 
            title="Oil & Energy Insurance" 
            image='https://res.cloudinary.com/devgodfrey/image/upload/v1688506023/oil-energy-bg.png'/>
        </div>

        <div data-aos="fade-up" 
        className={`relative px-8 md:px-16 xl:px-32 py-3 md:py-8 xl:py-0 
        overflow-hidden md:mt-3 xl:mt-0`}>
            <h1 className="text-bg-black text-3xl font-semibold">Oil & Energy Insurance</h1>
            <p className="text-bg-black my-6 mb-8 font-light text-lg">
            This form of insurance policy provides compensation to the insured in the event of occurrence 
            resulting during construction in offshore places. The policy provides coverage under two broad sections:
            </p>

            <h3 className="text-bg-black opacity-50 font-bold mb-3 text-lg">Section A</h3>
            <p className='font-light mb-8'>
                Insured will be compensated on occurrence of risk resulting to damage of Physical loss or 
                damage of contract works, including temporary works. This type of policy covers any incident 
                resulting from builders’ risk and cargo coverage, sue and labour, removal of wreck and debris 
                for a separate limit, existing property, expediting expenses, war and maintenance period.
            </p>

            <h3 className="text-bg-black opacity-50 font-bold mb-3 text-lg">Section B</h3>
            <p className='font-light mb-8'>
                Here the insured will be compensated on any Third-Party liabilities arising from 
                operations for an agreed limit.
            </p>

            <h3 className="text-bg-black opacity-50 font-bold my-3 mt-6 text-lg">Requirement</h3>
            <div>
                <ItemLi text="The scope of work of the project including bar charts showing project timeline, design of the facility and method of transit/installation at site."/>
                <ItemLi text="Project Value broken down to include major component parts."/>
                <ItemLi text="Details of project measurement and scale, contractors and suppliers of major project components parts to be covered while in transit to main"/>
                <ItemLi text="Details of project owner, including management experience, other operations, maintenance and safety procedures."/>
                
            </div>
        </div>

    </div>
  )
}

export default OilEnergy