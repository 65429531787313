import React from 'react';

import Profile from './Profile'

function Avatar(props) {
  return (
      <>
        {props.src ? 
                <img
                    src={props.src}
                    alt={props.alt}
                    className={` ${props.className}`}/>
                    
                : <Profile name={props.name} className={props.className} red={props.red} textCenter={props.textCenter} profileClass={props.profileClass}/>}
      </>
  )
}

export default Avatar;
