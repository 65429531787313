import React from 'react'
import TitleHOC from './TitleHOC'
import ItemLi from './ItemLi'

function ErectionRisks() {
  return (
    <div>
        <div className="mb-8">
            <TitleHOC 
            title="Erection All Risks Insurance" 
            image='https://res.cloudinary.com/devgodfrey/image/upload/v1688508728/fire-bg.png'/>
        </div>

        <div data-aos="fade-up" 
        className={`relative px-8 md:px-16 xl:px-32 py-3 md:py-8 xl:py-0 
        overflow-hidden md:mt-3 xl:mt-0`}>
            <h1 className="text-bg-black text-3xl font-semibold">Erection All Risks Insurance</h1>
            <p className="text-bg-black my-6 mb-8 font-light text-lg">
            These policies are designed to provide compensation for risk of loss arising out of the erection, 
            and installation of machinery, plants and steel structures, including physical damage to the 
            contract works and liability for third party bodily injury or property damage arising out of 
            these operations. Covered parties include; the general contractor, sub-contractors and in some 
            cases supplies and manufacturers of equipment. Examples of the types of projects for which EAR 
            coverage is typically purchased include; power plants, manufacturing and fabrication facilities, 
            water and waste water treatment facilities telecommunication center and the like.
            </p>

            <h3 className="text-bg-black opacity-50 font-bold mb-3 text-lg">Professional Indemity</h3>
            <p className='font-light mb-3'>
                This form of insurance is available to professionals against the consequence of professional 
                negligence by them or their employees. It is available for such professionals as: Accountants, 
                Architects, Doctors, Lawyers, Insurance Brokers, and Engineers etc.
            </p>

            <h3 className="text-bg-black opacity-50 font-bold mb-3 mt-8 text-lg">Products Liability</h3>
            <p className='font-light mb-3'>
            This cover provides compensation for losses as a result of insured’s legal liabilities for bodily 
            injury to person or loss of or damage to property caused by defects in goods sold, supplied, 
            repaired, manufactured and/or tested by the insured. Mechanics, Salon operators, Pharmaceutical 
            companies, restaurant operators etc. may find this cover very useful.
            </p>

            <h3 className="text-bg-black opacity-50 font-bold mb-3 mt-8 text-lg">Public Liability</h3>
            <p className='font-light mb-3'>
            This form of cover provides compensation for losses from liability to third parties for both 
            injury including (death and medical expenses) and for any loss or damage to third party property 
            as a result of the insured’s operations and occurring within the insured’s premises.
            </p>

            <h3 className="text-bg-black opacity-50 font-bold my-3 mt-6 text-lg">Required</h3>
            <div>
                <ItemLi text="Limit per occurrence"/>
                <ItemLi text="Annual / Aggregate limit"/>
            </div>
        </div>

    </div>
  )
}

export default ErectionRisks