import React from 'react'

import * as Icons from "react-icons/io";
import * as IconT from "react-icons/hi";

import ReactDOM from 'react-dom';
import {CSSTransition} from 'react-transition-group';
import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop';

import Loader from '../../../../UI_Elements/loader/Loader';

function BuyModalOverlay(props) {

    

    const content = (
        <div className='p-8 md:p-18 md:py-20 modal'>
            <div className='flex flex-col justify-center items-center w-5/5'>
                <h1 className='text-lg lg:text-2xl font-bold text-center mb-8'>Buy Insurance</h1>
                
                <div className='flex justify-between items-center py-4 dash_border-b w-full'>
                    <p className='text-xs md:text-sm dash__color'>Insurer:</p>
                    <h3 className='text-xs md:text-base font-bold text-center'>{props.title}</h3>
                </div>

                <div className='flex justify-between items-center py-4 dash_border-b w-full'>
                    <p className='text-xs md:text-sm dash__color'>Policy:</p>
                    <h3 className='text-xs md:text-base font-bold text-center'>{props.policy_name}</h3>
                </div>

                <div className='flex justify-between items-center py-4 dash_border-b w-full'>
                    <p className='text-xs md:text-sm dash__color'>Value of Asset:</p>
                    <h3 className='text-xs md:text-base font-bold text-center'>{props.value_of_asset}</h3>
                </div>

                <div className='flex justify-between items-center py-4 dash_border-b w-full'>
                    <p className='text-xs md:text-sm dash__color'>Rate:</p>
                    <h3 className='text-xs md:text-base font-bold text-center'>{props.rate}</h3>
                </div>
                
                <div className='flex justify-between items-center py-4 dash_border-b w-full'>
                    <p className='text-xs md:text-sm dash__color'>Premium:</p>
                    <h3 className='text-xs md:text-base font-bold text-center'>{props.premium}</h3>
                </div>

                <div className='flex justify-between items-center py-4 dash_border-b w-full'>
                    <p className='text-xs md:text-sm dash__color'>Validity:</p>
                    <h3 className='text-xs md:text-base font-bold text-center'>{props.validity} {props.validity === 1 ? "Year":"Years"}</h3>
                </div>


                <div className="flex justify-center items-center">

                    <button
                        className='form__submit py-3 mt-8 md:mt-12 px-8 md:px-16 lg:px-24 text-white mx-auto flex justify-center items-center'
                        onClick={props.onClick}>
                            <p className='text-white text-xs lg:text-base font-normal mr-4'>Make Payment</p>
                            <IconT.HiLockClosed
                            className='text-base rounded'/>
                        </button>{props.isLoading && <Loader/>}
                </div>

            </div>

            <div className='absolute top-4 right-4'>
                <button onClick={props.onCancel}>
                    <Icons.IoIosClose
                        className='text-base text-red-700 bg-red-300 rounded pointer'/>
                </button>
            </div>

        </div>
    )
    
    return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const BuyModal = props => {
    return (
        <React.Fragment>
            {props.show && <Backdrop onClick={props.onCancel}/>}
            <CSSTransition
                in={props.show}
                mountOnEnter
                unmountOnExit
                timeout={200}
                classNames="modal">
                <BuyModalOverlay {...props}/>
            </CSSTransition>
        </React.Fragment>
    );
};

export default BuyModal
