import React, { useEffect, useContext, useState, useCallback } from 'react'

import { useParams, Link } from 'react-router-dom';

import moment from 'moment'

import {AuthContext} from '../../../../context/auth-context';
import * as IconT from 'react-icons/io';

import StateButton from '../../../../UI_Elements/Button/StateButton';

import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop'

import Swal from 'sweetalert2';

import {useHttpClient} from '../../hooks/dashboard-http-hook'


import ImageCollectionItem from './ImageCollectionItem';
import DocumentCollectionItem from './DocumentCollectionItem';

function ClaimsItem() {
    const {id} = useParams();
    const auth = useContext(AuthContext)

    const [claimData, setClaimData] = useState(null);

    const { error, sendRequest} = useHttpClient();

    const [uploadImages, setUploadImages] = useState([])

    const [uploadDocuments, setUploadDocuments] = useState([])


    useEffect(() => {


        const fetchPolicies = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/claim/single/${id}`, "GET",null, {Authorization: 'Bearer '+auth.token});
                setClaimData(responseData.insurance)
                


            } catch (err) {
                console.log(error)
            }
        };
        fetchPolicies();

    }, [sendRequest, error, auth, id]);

    const imagesHandler = useCallback( (files) => {
        
        setUploadImages(files)
        

    }, [])

    const documentsHandler = useCallback((value) => {
        setUploadDocuments(value)
        
        
    }, [])


    const submitHandler = async event => {
        event.preventDefault();
        
        try {

            const formData = new FormData();
            formData.append('claimid', id)
            formData.append('images', JSON.stringify(uploadImages));
            formData.append('documents', JSON.stringify(uploadDocuments));

            


            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/claim/single/editclaimdata`, 
            "POST",formData, 
            {Authorization: 'Bearer '+auth.token});

            if(responseData){
                let swalAlert = await Swal.fire('Success', responseData.message, 'success');
                if (swalAlert.isConfirmed) {
                    
                }
            }
            
            

        } catch (err) {
            console.log(error)
        }

    }

    
    return (
        
            <>

        {claimData === null ? <Backdrop/> : ( 
            <>
            
            <div className='flex flex-col md:flex-row justify-between items-center'>
                <Link to="../claims" className="flex justify-center items-center">
                    <IconT.IoIosArrowDropleftCircle className="dash_action_button text-base mr-2"/>
                    <p className="dash__color text-base font-normal">All Claims</p>
                </Link>
                
                <button className='text-white bg-primary-red px-8 md:px-12 py-2 rounded' onClick={submitHandler}>Update</button>
            </div>

            
            <div className="mt-8 grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-5 gap-4 md:gap-4">
                <div className='col-span-3 px-4 py-2 pb-16 rounded-xl shadow bg-white'>
                    <h1 className="pb-2 dash__color w-full dash_border-b border-black">Claim Info</h1>

                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Id</h3>
                            <p className='dash__text text-left break-words text-sm'>{claimData.claim_id}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Date</h3>
                            <p className='dash__text text-left text-sm'>{moment(claimData.created_at).format('lll')}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Circumstance</h3>
                            <p className='dash__text text-left text-sm'>{claimData.circumstance}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Insuance Policy</h3>
                            <p className='dash__text text-left underline text-sm'>{claimData.insurance.policy.policy_name}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Insurer</h3>
                            <p className='dash__text text-left text-sm'>{claimData.insurer}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left'>Status</h3>
                            <div className='md:w-5/5 overflow-hidden'>
                                {claimData.status === "0" ? (
                                    <StateButton neutral>
                                    PENDING
                                </StateButton>
                                ) : claimData.status === "1" ? (
                                    <StateButton>
                                    APPROVED
                                </StateButton>
                                ) :
                                <StateButton expired>
                                    DECLINED
                                </StateButton>}
                            </div>
                        </div>
                        
                       
                    </div>
                </div>

                <section className='col-span-3 xl:col-span-2 px-4 py-2 pb-8 md:pb-16 rounded-xl shadow bg-white'>
                    <h1 className="pb-2 dash__color w-full dash_border-b border-black mb-4">Description</h1>
                    <div className="py-1">
                     {claimData.description}
                    </div>
                </section>


                <div className='col-span-3 lg:col-span-4 px-4 py-2 pb-8 md:pb-16 rounded-xl shadow bg-white'>
                    <h1 className="pb-2 dash__color w-full dash_border-b border-black mb-4 xl:mb-8">Images</h1>
                    {claimData && <ImageCollectionItem className="w-full" item images={[...claimData.images]} accept="image/*" browse="(jpg, png, jpeg)" onFiles={imagesHandler}/>}
                    
                    
                </div>


                <div className='col-span-3 lg:col-span-4 px-4 py-2 pb-8 md:pb-16 rounded-xl shadow bg-white mb-12'>
                    <h1 className="pb-2 dash__color w-full dash_border-b border-black mb-4 xl:mb-8">Documents</h1>
                    {claimData && <DocumentCollectionItem className="w-full" item documents={[...claimData.documents]} accept=".doc, .docx, .ppt, .pptx, .txt, .pdf" browse="(doc, pdf)" onFiles={documentsHandler}/>}
                </div>



            </div>
            </>
        )}
        </> 
        
    )
}

export default ClaimsItem