import React, { useRef } from 'react'
import Slider from "react-slick";

import * as Icons from "react-icons/bs";

import HOC from '../HOC/HOC';
import PolicyItem from '../Components/PolicyItem'
import {policyItems} from '../UIItems/policyItems'

function Policies() {
    
  const sliderRef = useRef(null);

    const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight:true,
    adaptiveWidth:true,
    arrows:true,
    // centerMode: true,
    dots:false,

    responsive: [
      {
        breakpoint: 1250,
        settings: {
          arrows: true,
          centerPadding: '30px',
          slidesToShow: 3,
          

        }
      },
        {
          breakpoint: 900,
          settings: {
            arrows: true,
            centerPadding: '30px',
            slidesToShow: 2,
            

          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerPadding: '20px',
            slidesToShow: 1,
            
          }
        }
      ]
  };
    return (
        <HOC>
            <div className=''>
                <div className='flex justify-between items-center'>
                  <h1 className='text__heading text-left'>Our Insurance Policies</h1>
                    <div className='md:flex justify-center items-center mr-8 hidden'>
                      <Icons.BsArrowLeftCircle className='mr-8 md:text-4xl cursor-pointer' onClick={() => sliderRef.current.slickPrev()} />
                      <Icons.BsArrowRightCircle className='md:text-4xl cursor-pointer' onClick={() => sliderRef.current.slickNext()}/>
                    </div>
                </div>
                <div className='my-8 md:my-12 '>
                    <Slider ref={sliderRef} {...settings}>
                      {policyItems.map((item) => {
                        return(
                          <div key={item.id}>
                            <PolicyItem  title={item.title} src={item.src} alt={item.alt}/>
                          </div>
                        )
                      })} 
                    </Slider>
                </div>
            </div>

        </HOC>
    )
}

export default Policies
