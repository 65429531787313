import React from 'react';


import Modal from '../../../../UI_Elements/Modal/Modal'

import {images} from '../../../../assests/index'

function ClaimsModal(props) {

    
  return (
      <Modal show={props.show} onCancel={props.onCancel} >
          <div className="bg-white p-8">
            <div className='flex flex-col justify-center items-center'>
                <img src={images.ClaimsFinish[0]} alt={images.ClaimsFinish[1]} />
                <p className='text-center opacity-70'>{`You have successfully made a claim on your ${props.policy} Policy with ${props.company}. Your claim ID is`} <strong>{props.claims_id}</strong></p>
                <button className='dash_button_red py-3 mt-8 md:mt-12 px-8 md:px-16 lg:px-24 rounded dash_button_pointer' onClick={props.onClaims}>See All Claims</button>
            </div>
        </div>
      </Modal>
  )
}

export default ClaimsModal;
