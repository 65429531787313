import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

import { saveAs } from 'file-saver';

import * as Icons from 'react-icons/ri'

import {images} from '../../../../assests/index'


function DocumentCollectionItem({
    onFiles,
    ...props
}) {

    const [dragEnter,
        setDragEnter] = useState(false);
    
    const [files,
            setFiles] = useState([]);

    const [urlFileList,
        setUrlFileList] = useState([...props.documents]);

    const [percent,setPercent] = useState(null);
    const [sent,setSent] = useState(true);

    const handleFiles = async (e) => {
        
        setFiles(e.target.files)
       
    };

    const handleDragEnter = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDragEnter(true);
    };
    const handleDragOver = (e) => { 
        e.stopPropagation();
        e.preventDefault();
        setDragEnter(true);
    };
    const handleDragLeave = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDragEnter(false);
    };
    const handleDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();
        
        setFiles(e.dataTransfer.files)

 
    };


    const UpdateDocumentUrls = useEffect(() =>{
        onFiles(urlFileList)
       
    },[onFiles, urlFileList, props])

    useEffect(() =>{
        
        const fileDisplay = async () =>{
            if(files){


                for(let file of files){
                    if (file.size >= 50000000000 ){
                        let swalAlert = Swal.fire('Error', `${file.name} is too large (${handleSize(file.size)})`, 'error');
                        if (swalAlert.isConfirmed) {
                            continue
                        }
                    }

                    try{

                        setSent(false)
                        const formData = new FormData();

                        formData.append("file", file);
                        formData.append('upload_preset', 'leadfort-claims');

                        const options = {
                            onUploadProgress: (progressEvent) => {
                                const {loaded, total} = progressEvent;
                                let percent = Math.floor( loaded * 100 / total);

                                if (percent < 100){
                                    setPercent(percent)
                                }
                            }


                        }

                        await axios.post("https://api.cloudinary.com/v1_1/devgodfrey/auto/upload",formData, options).then(res =>{
                            
                            
                            setUrlFileList((urls => [...urls, res.data.secure_url]))
                            setSent(true)
                            return res.data.secure_url;
                        })

                        
                    } catch(e){
                        console.log(e)
                    }
                }

            }
        }

        fileDisplay()
        
        
    },[files, UpdateDocumentUrls])


    const handleSize = (size) =>{
        if(size <= 1000000){
            return (size/1000).toFixed(2) + 'KB';
        }
        if(size === 1000000 || size <= 1000000000){
            return (size/1000000).toFixed(2) + 'MB';
        }
        if(size === 1000000000 || size <= 1000000000000){
            return (size/1000000000).toFixed(2) + 'GB';
        }
    }
    
    const onClearImg = (index)=>{

        setUrlFileList((documents) => documents.filter((_, i) => i !== index))
        
    }

    return (
        <div className={`${props.colSpan && 'md:col-span-2 '} ${props.className}`}>
            <h2 className='mb-2 text-sm '>{props.title}</h2>
            <div
                htmlFor="Document"
                className={`relative py-4 sm:py-8 md:py-16 xl:py-20 ${props.item && "xl:py-0"} px-2 mb-4 w-full rounded-lg border-2 border-dashed ${props.item && "border-0"} flex ${urlFileList.length !== 0 && 'opacity-100'} ${dragEnter && 'border-primary-black opacity-50'}`}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                accept={props.accept} >

                    {urlFileList.length === 0 && <div className='absolute top-0 left-0 h-full w-full text-primary-black flex flex-col justify-center items-center'>
                        <h3 className='text-sm md:text-lg opacity-80 text-center font-bold'>Drop your Documents here</h3>
                        </div>}

                    {!sent && <div className='absolute top-0 left-0 h-full w-full bg-black opacity-60 text-white flex flex-col justify-center items-center'>
                            <h3 className='text-sm md:text-base text-center font-bold'>Uploading Files</h3>
                            <p className='text-xs md:text-sm text-center'>Please Kindly wait for files to be uploaded</p>
                    </div>}
                    
                    <div className='flex flex-wrap align-items '>
                    {urlFileList.map((document,index) => (
                        <div key={index} className='relative flex flex-col justify-center items-center w-32 px-4 mx-2 shadow overflow-hidden cursor-pointer' onClick={() =>{
                            saveAs(document, `${document.split('/')[7]}`);
                        }}>
                            <img src={images.AdobeDownload[0]} alt={index} className="w-16 h-16 object-cover mx-2" />
                            <p className='text-xs text-center text-primary-black w-full'>{document.split('/')[7].length<10 ? document.split('/')[7] : `${document.split('/')[7].slice(0,10)}(..)`}.{document.split('/')[7].split(".")[1]}  </p>

                            {
                                percent> 0 && sent === false && (
                                    <div className=" bg-gray-200 h-1 absolute top-2/3 left-2 w-10/12 rounded-full">
                                        <div className="bg-primary-red h-1 rounded-full" style={{width: `${percent}%`}}></div>
                                    </div>
                                )
                                
                            }

                            <Icons.RiCloseFill className="absolute top-1 right-1 text-sm text-primary-red bg-white rounded cursor-pointer" onClick={() => onClearImg(index)}/>
                        </div>
                        
                        
                    ))}
                    </div>
                </div>

            <label htmlFor='Document' className='text-primary-red text-sm cursor-pointer'>
                BROWSE <span className="dash__color">{props.browse}</span>
            </label>
            <input
                id="Document"
                type="file"
                className='w-1 h-1 opacity-0 '
                accept={props.accept}    
                onChange={handleFiles}
                multiple/>
        </div>
    )
}

export default DocumentCollectionItem;
