
export const aboutItems = [
    {
        primarycolor:'#FC5C65',
        bordercolor: 'about__border',
        title: 'Risk Identification',
        text: 'We take initial step to study all the procedures you employ in your operations to unravel likely loss occurence'
    },
    {
        primarycolor:'#50E3C2',
        bordercolor: 'about__border',
        title: 'Risk Evaluation',
        text: 'We analyse and evaluate your risk exposure to determine maximum  loss should risk occur'
    },
    {
        primarycolor:'#A665FB',
        bordercolor: 'about__no__border',
        title: 'Risk Handling Measures',
        text: 'We recommend the most plausible risk handling techniques to mitigate loss occurence'
    }
]
