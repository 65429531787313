import React from 'react'
import { images } from '../../../../assests'
import HOC from '../../HOC/HOC'

function Privacy() {
  return (
    <HOC>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12'>
            <img src={images.aboutImage[0]} alt={images.aboutImage[1]}/>

            <div>
                <p className="text-primary-red uppercase text-sm mb-3">More about us</p>
                <h3 className="text-primary-black font-bold text-2xl lg:text-3xl">Privacy and Safety</h3>

                <p className='text-primary-black mt-4'>
                    Our capabilities and intellectual capital are enhanced by our deep industry 
                    expertise and hands- on, collaborative approach.
                </p>

                <div className={`mt-10 flex `} >
                                    
                    <div 
                    style={{backgroundColor:`#FC5C6510`, borderRadius:'50%'}} 
                    className='w-20 md:w-12 lg:w-16 xl:w-12 h-6 md:h-10 mx-auto flex justify-center items-center '>
                        <div style={{backgroundColor:'#FC5C65', zIndex:'4', borderRadius:'50%'}} 
                        className="w-2 h-2 rounded-full mx-auto animate-bounce  "></div>
                    </div>
                    <div className='ml-2'>
                        <h4 className="text__subtitle font-bold ">Quality Services</h4>
                        <p className= {`text-base pt-2`}>
                            Our professionals see what others don’t, 
                        challenge conventional thinking, and consistently deliver innovative solutions.
                        </p>
                    </div>
                </div>

                <div className={`mt-10 flex `} >
                                    
                    <div 
                    style={{backgroundColor:`#FC5C6510`, borderRadius:'50%'}} 
                    className='w-20 md:w-12 lg:w-16 xl:w-12 h-6 md:h-10 mx-auto flex justify-center items-center'>
                        <div style={{backgroundColor:'#FC5C65', zIndex:'4', borderRadius:'50%'}} 
                        className="w-2 h-2 rounded-full mx-auto animate-bounce  "></div>
                    </div>
                    <div className='ml-2'>
                        <h4 className="text__subtitle font-bold ">Innovation Solutions</h4>
                        <p className= {`text-base pt-2`}>
                        We work side by side with senior executives to accelerate execution 
                        through a blend of analytical and management approaches.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </HOC>
    
  )
}

export default Privacy