import React from 'react'

function HOC(props) {
    const {className} = props
    return (
        <div data-aos="fade-up" className={`relative px-8 md:px-16 xl:px-32 py-8 md:py-16 overflow-hidden ${className}`}>
            {props.children}
        </div>
    )
}

export default HOC
