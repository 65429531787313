import React from 'react'
import HOC from '../../HOC/HOC'

function Brief() {
  return (
    <HOC className='bg-bg-black  '>
        <h1 
        className='mb-8 text-xl md:text-2xl lg:text-3xl leading-5 lg:leading-8 w-4/6 lg:w-3/6 font-semibold text-white'>
            Have a brief view about <br/>Lead Fort
        </h1>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 lg:gap-8'>
            <div className="border-border-color border-2 border-dashed p-3 rounded-md">
                <h5 className='text-primary-red text-xs mb-3 font-light'>HISTORY AND PROFILE</h5>
                <p className='text-white text-base mb-3 font-extralight'>We are proud of our reputation for integrity and professionalism, and by focusing 
                    on the needs of our customers, we are known for our innovative insurance services.
                </p>
                <p className='text-white text-base mb-3 font-extralight'>Leadfort Insurance Brokers Limited “Leadfort” is a Nigerians wholly owned company, 
                    with corporate head office at 14A, Bosun Adekoya Street, Oniru extension, 
                    Zone 10 Lekki Phase 1, Lekki. The company was established to develop, package 
                    and deliver quality and highly innovative insurance products that best satisfy 
                    customer needs, whilst operating a highly ethical, efficient, resourceful and 
                    profitable organization that will transcend the expectation of all stakeholders 
                    in wealth creation.
                </p>
                <p className='text-white text-base mb-3 font-extralight'>We believe that the extent and depth of our service delivery, fine-tuned to the peculiarity 
                    of each customer, and coupled with the trusting relationships we have built with our 
                    clients form the basis of our strength in insurance that rises above the times.
                </p>
            </div>

            <div className="border-border-color border-2 border-dashed p-3 h-fit rounded-md">
                <h5 className='text-primary-red text-xs mb-3 font-light'>Our Corporate Philosophy</h5>
                <p className='text-white text-base mb-3 font-extralight'>Leadfort strongly emphasizes on having a long-term productive 
                relationship of mutual respect and trust with all our clients. We pay attention to every detailed risk 
                situation and respond swiftly to every claim and/or every situation requiring our attention thereby 
                protecting the wealth and investments of our clients. 
                </p>

                <p className='text-white text-base font-extralight'>
                We deploy all our expertise and an exceptional degree of personal attention in carrying out our 
                functions/duties. We never forget that every client is unique with differential problems and specific needs. In pursuance of this, we always rely on the strength of our in-house expertise and highly valued crop of creative professionals, and this, enable us guaranty the provision of services of the highest possible standards.
                </p>
                
            </div>
        </div>
        <div className='pb-12'></div>
    </HOC>
  )
}

export default Brief