import React, {useMemo, useEffect, useState, useContext, useCallback} from 'react'
import { useNavigate } from 'react-router-dom';
// REACT HOOKS
import {useTable, usePagination} from 'react-table'
import moment from 'moment';

import * as IconT from 'react-icons/io'
import * as Icons from 'react-icons/md'

import { useHttpClient } from '../hooks/dashboard-http-hook'
import {AuthContext} from '../../../context/auth-context';

import Backdrop from '../../../UI_Elements/Backdrop/Backdrop'
import StateButton from '../../../UI_Elements/Button/StateButton';

// DATA
import {INSURANCES_COLUMNS} from '../util/columns';



function InsurancesTable() {    

    const auth = useContext(AuthContext)

    const history = useNavigate()

    const [tableData, setTableData] = useState();

    const [cellData , setCellData] = useState([]);

    const [detailsData, setDetailsData] = useState({
        "current_page" : '1',
        "page_number":"5",
        "status":"2"
    })

    const [pages, setPages] = useState([]);

    const {isLoading, error, sendRequest} = useHttpClient();

    useEffect(() => {
        setTableData(null)

        const formData = new FormData()
        formData.append('page_number',detailsData.page_number);
        formData.append('status',detailsData.status);
        

        const fetchPolicies = async() => {
            
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/insurance/list?page=${detailsData.current_page}`, "POST", formData , {Authorization: 'Bearer '+auth.token});
                setTableData(responseData.insurance);
                setPages(responseData.insurance.links)
                

                if(responseData){
                    setCellData([])
                    for(let file of responseData.insurance.data){
                        setCellData((cell)=>[...cell, {
                            "id":file.insurance_id,
                            "policy": file.policy.policy_name,
                            "insurer": file.attached_policy.company.company_name,
                            "premium": file.premium,
                            "start_date": moment(file.created_at).format('lll'),
                            "expiry_date": moment(file.created_at).add(1, 'years').format('lll'),
                            "status": file.status,
                            "actions": file.status === '2' ? 'insured' : 'not insured'
                        }])
                    }
                }



            } catch (err) {
                console.log(error)
            }
        };
        fetchPolicies();

    }, [sendRequest, error, auth, detailsData]);


    const singleInsurance = useCallback((e, id) =>{
        e.preventDefault()
        history(id)
    },[history])

    const pageChangeHandler = (url) =>{
        if(url === null){
            return
        }

        let pageCustom = url.split("=")[1];

        setDetailsData({
            ...detailsData,
            "current_page":pageCustom.toString()
        })
    }

    const pageSizeChangeHandler = (number) => {
        setDetailsData({
            ...detailsData,
            "current_page":"1",
            "page_number":number.toString()
        })
    }

    const columns = useMemo(() => INSURANCES_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)

   

    return (
        <>
        {isLoading && <Backdrop overlay/>}
        <h1 className="dash__color text-base font-normal">All My Insurances</h1>
        
        <div className="bg-white pb-16 shadow-md sm:rounded-lg mt-16 overflow-x-auto">
            <table {...getTableProps()} className="min-w-full">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} className="text-xs whitespace-nowrap">{column.render('Header')}</th>
                                ))}
                            <th>{' '}</th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()}>

                                {row
                                    .cells
                                    .map(cell => {
                                        if(cell.value === "1"){
                                            return <td {...cell.getCellProps()} ><StateButton  neutral>
                                            PENDING
                                        </StateButton></td>
                                        }
                                        if(cell.value === "2"){
                                            return <td {...cell.getCellProps()} ><StateButton>
                                            ACTIVE
                                        </StateButton></td>
                                        }
                                        if(cell.value === "3"){
                                            return <td {...cell.getCellProps()} ><StateButton expired>
                                            EXPIRED
                                            </StateButton></td>
                                        }

                                        if(cell.value === "insured"){
                                            return <td {...cell.getCellProps()} ><div className=" text-sm py-3 px-4 dash_button_neutral rounded-md text-center whitespace-nowrap ">Re-insure</div></td>
                                        }

                                        if(cell.value === "not insured"){
                                            return <td {...cell.getCellProps()} ><div className=" text-sm py-3 px-4 dash_button_red rounded-md text-center whitespace-nowrap ">Re-insure</div></td>
                                        }
                                        
                                        return <td {...cell.getCellProps()} className="text-sm py-4 px-6 whitespace-nowrap ">{cell.render('Cell')}</td>
                                    })}
                                <td className='py-4 px-6 m-2 bg-transparent cursor-pointer text-sm whitespace-nowrap ' onClick={(e) => singleInsurance(e,row.cells[0].value)}><IconT.IoIosArrowDroprightCircle className="dash_action_button text-base"/></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-30'></div>
           {tableData && (
                <div className='flex justify-center items-center w-full mt-8 pl-4 md:pl-0 '>
                    
                <div className="w-full justify-center items-center flex flex-wrap">
                <span>
                        PAGE{' '}
                        <span className='mr-2'>
                            {tableData.current_page}
                            {' '} of {tableData.last_page}
                        </span>{' '}
                    </span>
                    {' '}
                    <button className={`${tableData.prev_page_url !== null ? 'dash_cell_bg' : 'bg-white dash_disable_border'} h-8 w-8 dash_border_radius inline-flex justify-center items-center`} onClick={() => pageChangeHandler(tableData.first_page_url)} >
                        <Icons.MdArrowBackIos className={`${tableData.prev_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-bold`}/>
                        <Icons.MdArrowBackIos className={`${tableData.prev_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-extrabold -ml-2`}/>
                    </button>{' '}
                    <button className={`${tableData.prev_page_url !== null ? 'dash_cell_bg' : 'bg-white dash_disable_border'} h-8 w-8 dash_border_radius inline-flex justify-center items-center`} onClick={() => pageChangeHandler(tableData.prev_page_url)} >
                        <Icons.MdArrowBackIos className={`${tableData.prev_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-extrabold`}/>
                    </button>{' '}

                    {
                        pages.map((option, index) => {
                            if(option.label === "&laquo; Previous" || option.label === "Next &raquo;"){
                                return (
                                    <div key={index}>{""}</div>
                                )
                            }
                            return(
                                <React.Fragment key={index}>
                                <button  className={`${option.active === true ? 'dash_cell_bg' : 'bg-white dash_disable_border'} h-8 w-8 dash_border_radius inline-flex justify-center items-center`} onClick={() => pageChangeHandler(option.url)}>
                                    {option.label}
                                </button>{' '}
                                </React.Fragment>
                            )
                        })
                    }
                    
                    <button className={`${tableData.next_page_url !== null ? 'dash_cell_bg' : 'bg-white dash_disable_border'} h-8 w-8 dash_border_radius inline-flex justify-center items-center`} onClick={() => pageChangeHandler(tableData.next_page_url)} >
                        <Icons.MdArrowForwardIos className={`${tableData.next_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-extrabold`}/>
                    </button>{' '}
                    <button className={`${tableData.next_page_url !== null ? 'dash_cell_bg' : 'bg-white dash_disable_border'} h-8 w-8 dash_border_radius inline-flex justify-center items-center`} onClick={() => pageChangeHandler(tableData.last_page_url)} >
                        <Icons.MdArrowForwardIos className={`${tableData.next_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-bold -mr-2`}/>
                        <Icons.MdArrowForwardIos className={`${tableData.next_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-extrabold`} />
                    </button>{' '}

                    
                    <span className='mt-2 md:mt-0 ml-14   sm:ml-8'>
                        View{' '}
                        <select
                            value={tableData.per_page}
                            onChange={e => pageSizeChangeHandler(Number(e.target.value))}
                            className='select__border p-2 px-4'>
                            {[
                                5,
                                10,
                                15,
                                20
                            ].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                        {' '}
                        per page
                    </span>
                </div>
            </div>
           )}
            
        </div>
        </>              
    )
}

export default InsurancesTable
