import React from 'react'

// REACT COMPONENTS
import HOC from '../HOC/HOC'

// import InsurancesItem from '../Components/InsurancesItem'
import InsurancesTable from '../Components/InsurancesTable'

function Insurances() {

    return (
        <HOC>
            

            <InsurancesTable/>

        </HOC>
    )
}

export default Insurances
