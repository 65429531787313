import React from 'react'

import * as Icons from "react-icons/fa";

import './Footer.css'

function Footer() {
    return (
        <div>
            <div className='flex flex-col lg:flex-row  justify-between gap-8 lg:gap-0 items-start px-8 md:px-16 xl:px-32 py-8 md:py-16'>
                <p className='w-5/5 lg:w-2/5'>Leadfort insurance brokers is your one-stop shop for insurance services and
                    resources. Our service delivery and lead time is second to none.</p>
                <div className=''>
                    <p className='mb-2 lg:mb-6 lg:text-right '>EMAIL</p>
                    <p className='text__footer'>help@lendfort.com</p>
                </div>
                <div>
                    <p className='mb-2 lg:mb-6'>WE ARE SOCIAL</p>
                    <div className='footer__color text-xl flex justify-between items-center '>
                        <Icons.FaFacebookF/>
                        <Icons.FaInstagram/>
                        <Icons.FaTwitter/>
                    </div>
                </div>
            </div>


            <div className='flex flex-col lg:flex-row justify-between items-start px-8 md:px-16 xl:px-32 py-4 md:py-6 bg-black text-white text-base font-normal'>
                <p className='mb-8 lg:mb-0'>© Copyright 2021. Copyright Leadfort Insurance Brokers Ltd</p>
                <div>
                    <ul className='flex flex-col md:flex-row justify-around items-start md:items-center '>
                        <li className='md:mr-8 '>Contact</li>
                        <li className='md:mr-8'>Privacy Policy</li>
                        <li className=''>Terms and Conditions</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Footer
