import React, {useState, useCallback, useEffect, useContext, useRef} from 'react';

import * as IconT from 'react-icons/bi'

import {useHttpClient} from '../../hooks/dashboard-http-hook';
import {useParams} from 'react-router-dom';

import DetailsModal from './PoliciesDetails';
import BuyModal from './PoliciesBuy';

import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop'

import PaystackButton from './PaystackButton';

import { AuthContext } from '../../../../context/auth-context';
import { DataContext } from '../../context/DataContext';

function PolicyInsurers() {

    const auth = useContext(AuthContext);
    const customerData = useContext(DataContext);

    const paystackClick = useRef()

    const params = useParams()

    const [query,
        setQuery] = useState(null);

    const [details,
        setDetails] = useState(null);

    const [dataQuery,
            setDataQuery] = useState(null);

    const [isDetails,
        setIsDetails] = useState(false);

    const [isBuy,
        setIsBuy] = useState(false);

    const [data,
        setData] = useState();

    const [paymentData,
            setPaymentData] = useState(null);
    
    

    const {isLoading, error, sendRequest} = useHttpClient();


    useEffect(() => {
        const fetchPolicies = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/insurance/buy/two/${params.id}`, "GET", null, {Authorization: 'Bearer '+auth.token});
                setData(responseData)
                setDataQuery(responseData.policy.attachpolicy)
                
                

            } catch (err) {
                console.log(error)
            }
        };

        fetchPolicies();

    }, [sendRequest, error, params, auth]);

    useEffect(()=>{
       
       const testing = () =>{

        if(data){
            let dataFilter = data.policy.attachpolicy;
            


            if(query === "" || query === undefined || query === null) {
                
                return setDataQuery(data.policy.attachpolicy)
            }
            else{
                
                let dataFilters = dataFilter.filter((option) => {
                    const optionName = option.company.company_name.toLowerCase();
                    return optionName.includes(query.value)
                }) 
        
        
                setDataQuery(dataFilters)
            }
        }

       }

       testing();

        

    },[query, data])

    const detailsHandler = useCallback((id) => {

        for (let company in data.policy.attachpolicy) {

            if (id === data.policy.attachpolicy[company].id) {

                setIsDetails(true)
                setDetails(data.policy.attachpolicy[company])

                return
            }
        }
    }, [data])

    const buyHandler = useCallback((id) => {

        for (let company in data.policy.attachpolicy) {

            if (id === data.policy.attachpolicy[company].id) {
                setIsBuy(true)
                setDetails(data.policy.attachpolicy[company])
                return
            }
        }
    }, [data])

    const buySubmitHandler = async event => {
        event.preventDefault();

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/insurance/buy/two`, "POST", JSON.stringify(
                {attach_policies_id: details.id.toString(), 
                insurance_id: data.insurance.insurance_id}), {
                'Content-Type': 'application/json',
                Authorization: 'Bearer '+auth.token
            });
            
            setPaymentData(responseData)
           
            
            
        

        } catch (err) {
            console.log(err)
        }

    }

    useEffect(() => {
        
        if(paymentData){
            payClick()
            
        }
    },[paymentData])

    const payClick = () =>{
            paystackClick.current.click()
        
    }

    const onCancelDetails = () => {
        setIsDetails(!isDetails)
    }

    const onCancelBuy = () => {

        setIsBuy(!isBuy)
    }

    return (
        <div>
            {isLoading && <Backdrop overlay/>}
            <div
                className='flex items-center justify-between rounded-full shadow-sm m-2 p-3 w-3/3 lg:w-2/5 xl:w-1/5 bg-white'>
                <input
                    type="text"
                    className='text-sm outline-none flex-grow'
                    placeholder='search...'
                    id='search_select'
                    onInput={(e) => {
                    e.preventDefault();
                    setQuery({value: e.target.value});
                    
                }}/>
                <label htmlFor='search_select'>
                    <IconT.BiSearch className='text-slate-500'/>
                </label>
            </div>

            {!isLoading && !isDetails && !isBuy && <div
                className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 items-center mt-8 gap-4'>

                {dataQuery && dataQuery.map((company) => {
                        return (

                            <div
                                className='flex justify-center items-start bg-white dash__border p-4'
                                key={company.id}>
                                <div>
                                    <img
                                        src={company.company.image}
                                        alt={company.company.company_name}
                                        className="w-16 h-16 object-contain"/>
                                </div>

                                <div className='flex flex-col justify-start items-start flex-grow ml-4'>
                                    <h3 className='mb-4 font-bold '>{company.company.company_name}</h3>
                                    <div className='flex justify-between items-center'>
                                        <div className='flex flex-col items-start about__border pr-4'>
                                            <h3 className='text-sm font-bold '>{company.rate}%</h3>
                                            <p className='text-xs mt-1 opacity-70'>RATE</p>
                                        </div>
                                        <div className='flex flex-col items-start pl-4'>
                                            <h3 className='text-sm font-bold '>N{company.rate * data.insurance.value_of_assets /100}</h3>
                                            <p className='text-xs mt-1 opacity-70'>PREMIUM</p>
                                        </div>
                                    </div>
                                    <div className='flex justify-center items-center mt-4'>
                                        <button
                                            className='dash_button_neutral py-2 px-4 mr-4 rounded-full dash_button_pointer text-xs'
                                            onClick={(e) => {
                                            e.preventDefault();
                                            detailsHandler(company.id)
                                        }}>
                                            See details
                                        </button>
                                        <button
                                            className='dash_button_buy py-2 px-6 rounded-full dash_button_pointer text-xs'
                                            onClick={(e) => {
                                            e.preventDefault();
                                            buyHandler(company.id)
                                        }}>
                                            Buy
                                        </button>
                                    </div>
                                </div>

                            </div>

                        )
                    })}

            </div>
}

            {isDetails && (<DetailsModal
                onCancel={onCancelDetails}
                show={isDetails}
                title={details.company.company_name}
                text={details.description}
                file={details.policy_document}
                ext={details
                .policy_document
                .split('.')[-1]}
                fileSize={details.policy_document.size / 1000000}
                onClick={(e) => {
                e.preventDefault();
                setIsDetails(false);
                setIsBuy(true)
            }}/>)
}

            {isBuy && (<BuyModal
                onCancel={onCancelBuy}
                show={isBuy}
                title={details.company.company_name}
                policy_name={data.policy.policy_name}
                value_of_asset={data.insurance.value_of_assets}
                rate={details.rate}
                premium={details.rate * data.insurance.value_of_assets /100}
                onClick={buySubmitHandler}
                validity={1}/>)
}

            {/* {isPayment && (<FlutterwaveModal
                onCancel={onCancelPayment}
                show={isPayment}
                company_name={details.company.company_name}
                policy_name={data.policy.policy_name}
                amount={paymentData.payments.amount}
                firstname={customerData.firstname}
                lastname={customerData.lastname}
                email={customerData.email}
                phonenumber={customerData.phone_number}
                reference={paymentData.reference}
                />)
} */}

        {/* {
            isPayment && (
                <PaystackModal 
                onCancel={onCancelPayment}
                show={isPayment}
                amount={paymentData.payments.amount}
                email={customerData.email}
                reference={paymentData.reference}
                />
            )
        } */}
        {
            paymentData && (<PaystackButton  amount={paymentData.payments.amount}
        email={customerData.email}
        reference={paymentData.reference} paystackClick={paystackClick}/>)
    }
    



        </div>
    )
}

export default PolicyInsurers;
