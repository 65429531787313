import React, {useMemo, useEffect, useState, useContext} from 'react'
// import { useNavigate } from 'react-router-dom';
// REACT HOOKS
import {useTable, usePagination} from 'react-table'
import moment from 'moment';
import csvDownload from 'json-to-csv-export'

import {images} from '../../../../assests/index'

import * as Icons from 'react-icons/md';
import * as IconT from 'react-icons/bi'
import * as IconG from 'react-icons/gr'


import { useHttpClient } from '../../hooks/dashboard-http-hook'
import {AuthContext} from '../../../../context/auth-context';

import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop'

// DATA
import {PAYMENTS_COLUMNS} from '../../util/columns';
import { usePdfClient } from '../../hooks/pdf-http-hook';



function PaymentTable() {    

    const auth = useContext(AuthContext)

    // const history = useNavigate()

    const [tableData, setTableData] = useState();

    const [cellData , setCellData] = useState([]);

    const [detailsData, setDetailsData] = useState({
        "current_page" : '1',
        "page_number":"5",
        "status":"Completed",
        "search_text": ""
    })

    const [pages, setPages] = useState([]);

    const {isLoading, error, sendRequest} = useHttpClient();

    const {sendPdfRequest} = usePdfClient()

    useEffect(() => {
        setTableData(null)

        const formData = new FormData()
        formData.append('page_number',detailsData.page_number);
        formData.append('status',detailsData.status);
        formData.append("search_text", detailsData.search_text)

        const fetchPolicies = async() => {
            
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/payments/list?page=${detailsData.current_page}`, "POST", formData , {Authorization: 'Bearer '+auth.token});
                
                setTableData(responseData.payments);
                setPages(responseData.payments.links)
                
                

                if(responseData){
                    setCellData([])
                    for(let file of responseData.payments.data){
                        setCellData((cell)=>[...cell, {
                            "id":file.reference,
                            "date": moment(file.created_at).format('lll'),
                            "amount": file.amount,
                            "status": file.status,
                            "insurer":file.insurer,
                            "policy":file.insurance.policy.policy_name
                        }])

                        
                    }
                }



            } catch (err) {
                console.log(error)
            }
        };
        fetchPolicies();

    }, [sendRequest, error, auth, detailsData]);


    const singlePayment = async(e,id) =>{
        
        try{
            const responseData = await sendPdfRequest(`${process.env.REACT_APP_API_CALL}/customers/payments/recipt/${id}`, "GET", null , {Authorization: 'Bearer '+auth.token, Accept: "application/*"});
            window.open(URL.createObjectURL(responseData), '_blank')
            
            
        }
        catch(err){
            console.log(error)
        }
    }  

    const pageChangeHandler = (url) =>{
        if(url === null){
            return
        }

        let pageCustom = url.split("=")[1];

        setDetailsData({
            ...detailsData,
            "current_page":pageCustom.toString()
        })
    }

    const pageSizeChangeHandler = (number) => {
        setDetailsData({
            ...detailsData,
            "page_number":number.toString()
        })
    }

    const columns = useMemo(() => PAYMENTS_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)

   

    return (
        <>
        {isLoading && <Backdrop overlay/>}
        <div className='flex justify-between items-center flex-wrap mb-8'>
            <div
                    className='flex items-center justify-between rounded-full shadow-sm m-2 p-3 w-full lg:w-2/5 xl:w-1/5 bg-white'>
                    <input
                        type="text"
                        className='text-sm outline-none border-none flex-grow'
                        placeholder='search...'
                        id='search_select'
                        onInput={(e) => {
                        console.log(e)
                        setDetailsData({
                            ...detailsData,
                            search_text:e.target.value
                        })
                    }}/>
                    <label htmlFor='search_select'>
                        <IconT.BiSearch className='text-slate-500'/>
                    </label>
                </div>

                <button className="flex justify-center items-baseline text-primary-black mt-2 ml-2 md:mt-0 md:ml-0" onClick={() => csvDownload(cellData, "PaymentsData.csv")}>
                    <IconG.GrDownload className='text-sm mr-2'/>
                    <p className='text-sm' >Export table</p>
                </button>

        </div>
        
        <div className="bg-white pb-16 shadow-md sm:rounded-lg mt-16 overflow-x-auto mi min-w-full">
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} className='text-xs'>{column.render('Header')}</th>
                                ))}
                            <th >{' Receipt'}</th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()}>

                                {row
                                    .cells
                                    .map(cell => {
                                        
                                        
                                        return <td {...cell.getCellProps()} className="text-sm py-4 px-6 whitespace-nowrap min-w-full">{cell.render('Cell')}</td>
                                    })}
                                <td className='py-4 px-2 bg-transparent cursor-pointer flex justify-start items-center' onClick={(e) => singlePayment(e,row.cells[0].value)}>
                                    <img src={images.AdobeDownload[0]} alt={images.AdobeDownload[1]} className="object-cover  "/>
                                    <p className='text-primary-red text-xs ml-4 md:text-sm my-auto '>Download</p>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
           {tableData && (
                <div className='flex justify-center items-center w-full mt-8 pl-4 md:pl-0 '>
                    
                <div className="w-full justify-center items-center flex flex-wrap">
                <span>
                        PAGE{' '}
                        <span className='mr-2'>
                            {tableData.current_page}
                            {' '} of {tableData.last_page}
                        </span>{' '}
                    </span>
                    {' '}
                    <button className={`${tableData.prev_page_url !== null ? 'dash_cell_bg' : 'bg-white dash_disable_border'} h-8 w-8 dash_border_radius inline-flex justify-center items-center`} onClick={() => pageChangeHandler(tableData.first_page_url)} >
                        <Icons.MdArrowBackIos className={`${tableData.prev_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-bold`}/>
                        <Icons.MdArrowBackIos className={`${tableData.prev_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-extrabold -ml-2`}/>
                    </button>{' '}
                    <button className={`${tableData.prev_page_url !== null ? 'dash_cell_bg' : 'bg-white dash_disable_border'} h-8 w-8 dash_border_radius inline-flex justify-center items-center`} onClick={() => pageChangeHandler(tableData.prev_page_url)} >
                        <Icons.MdArrowBackIos className={`${tableData.prev_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-extrabold`}/>
                    </button>{' '}

                    {
                        pages.map((option, index) => {
                            if(option.label === "&laquo; Previous" || option.label === "Next &raquo;"){
                                return (
                                    <div key={index}>{""}</div>
                                )
                            }
                            return(
                                <React.Fragment key={index}>
                                <button  className={`${option.active === true ? 'dash_cell_bg' : 'bg-white dash_disable_border'} h-8 w-8 dash_border_radius inline-flex justify-center items-center`} onClick={() => pageChangeHandler(option.url)}>
                                    {option.label}
                                </button>{' '}
                                </React.Fragment>
                            )
                        })
                    }
                    
                    <button className={`${tableData.next_page_url !== null ? 'dash_cell_bg' : 'bg-white dash_disable_border'} h-8 w-8 dash_border_radius inline-flex justify-center items-center`} onClick={() => pageChangeHandler(tableData.next_page_url)} >
                        <Icons.MdArrowForwardIos className={`${tableData.next_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-extrabold`}/>
                    </button>{' '}
                    <button className={`${tableData.next_page_url !== null ? 'dash_cell_bg' : 'bg-white dash_disable_border'} h-8 w-8 dash_border_radius inline-flex justify-center items-center`} onClick={() => pageChangeHandler(tableData.last_page_url)} >
                        <Icons.MdArrowForwardIos className={`${tableData.next_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-bold -mr-2`}/>
                        <Icons.MdArrowForwardIos className={`${tableData.next_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-extrabold`} />
                    </button>{' '}

                    
                    <span className='mt-2 md:mt-0 ml-14   sm:ml-8'>
                        View{' '}
                        <select
                            value={tableData.per_page}
                            onChange={e => pageSizeChangeHandler(Number(e.target.value))}
                            className='select__border p-2 px-4'>
                            {[
                                5,
                                10,
                                15,
                                20
                            ].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                        {' '}
                        per page
                    </span>
                </div>
            </div>
           )}
            
        </div>
        </>              
    )
}

export default PaymentTable
