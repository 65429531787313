import React from 'react'
import { images } from '../../../../assests'

function ItemLi(props) {

    const {text} = props
  return (
    <div className="flex items-center my-4">
        <img src={images.dot[0]} alt={images.dot[1]}/>
        <p className='font-light ml-2 text-bg-black'>{text}</p>
    </div>
  )
}

export default ItemLi