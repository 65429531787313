import React, {useState, useEffect, useContext} from 'react';
import {useForm} from '../../../../hooks/form-hook';
// import {useHttpClient} from '../../../../hooks/http-hook';
import { useHttpClient } from '../../hooks/dashboard-http-hook';
import Input from '../../../../UI_Elements/Input/Input';
import Loader from '../../../../UI_Elements/loader/Loader';
import { AuthContext } from '../../../../context/auth-context';

import {VALIDATOR_MINLENGTH} from '../../../../util/validators';

function Password() {

    const auth = useContext(AuthContext)
    const [passwordError, setPasswordError] = useState(false)


    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
        inputHandler] = useForm({
            newPassword: {
                value: '',
                isValid: false
            },
            oldPassword: {
                value: '',
                isValid: false
            },
            confirmPassword: {
                value: '',
                isValid: false
            }

    }, false);


    useEffect(() => {

        console.log("ran")

        if(formState.inputs.newPassword.value !== formState.inputs.confirmPassword.value){
            setPasswordError(true)
        }
        else{
            setPasswordError(false)
        }

    },[formState, formState.inputs.newPassword.value])

    const accountSubmitHandler = async e => {
        e.preventDefault();

        try {

            const data = JSON.stringify({
                old_password: formState.inputs.oldPassword.value, 
                new_password: formState.inputs.newPassword.value,
                new_password_confirmation: formState.inputs.confirmPassword.value})
            await
            sendRequest(`${process.env.REACT_APP_API_CALL}/customers/editpassword`, 'POST', data, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            });

        } catch (err) {
            console.log(error)
        }
    }

    return (
        <div className='p-12 bg-white w-full'>
            <div>
                <form onSubmit={accountSubmitHandler}>
                     <div className='grid grid-cols-1 gap-8 '>
                        <div className='w-5/5 lg:w-3/5 mx-auto'>
                        <div className=''>
                        <Input
                            label="Old Password"
                            onInput={inputHandler}
                            validators={[VALIDATOR_MINLENGTH(8)]}
                            key="oldPassword"
                            id="oldPassword"
                            type="password"
                            placeholder="kindly input old password"
                            className="dash-p-4 "
                            element="input"
                        />
                        </div>

                        <div className='my-8'>
                        <Input
                            label="New Password"
                            onInput={inputHandler}
                            validators={[VALIDATOR_MINLENGTH(8)]}
                            key="newPassword"
                            id="newPassword"
                            type="password"
                            className="dash-p-4"
                            element="input"/>
                        </div>

                        <div className='flex flex-col justify-center items-start w-full'>
                            <Input
                                label="Confirm New Password"
                                onInput={inputHandler}
                                validators={[VALIDATOR_MINLENGTH(8)]}
                                key="confirmPassword"
                                id="confirmPassword"
                                type="password"
                                className="dash-p-4 w-full"
                                element="input"/>

                            {passwordError && <p className='text-primary-red text-xs mt-2' >This password is different</p>}
                        </div>

                        </div>

                        <div className='my-8 mx-auto flex justify-start items-center'>
                            <input
                                type='submit'
                                className={`form__submit py-3 px-8 md:px-16 lg:px-24 text-white mr-2 `}
                                value="Save Changes"/> {isLoading && <Loader/>}
                        </div>

                    </div>

                </form>
            </div>
        </div>
    );

}
export default Password;
