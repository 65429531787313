import React from 'react'
import Coverage from '../Coverage'
import Cover from './Cover'
import Brief from './Brief'
import Mission from './Mission'
import Privacy from './Privacy'
import {Board} from './Board'
import { boardItems } from '../../UIItems/boardItems';

function About() {
    console.log(boardItems)
  return (
    <div>
        <Cover/>
        <Brief/>
        <Mission/>
        <Privacy/>
        <Board/>
        <Coverage/>
    </div>
  )
}

export default About