import React,{ useEffect, useState} from 'react'

import { useSearchParams } from "react-router-dom";

import Input from '../UI_Elements/Input/Input'
import Loader from '../UI_Elements/loader/Loader';

import {images} from '../assests/index'

import {useForm} from '../hooks/form-hook'
import {useHttpClient} from '../hooks/http-hook';

import './auth.css'


import {VALIDATOR_MINLENGTH} from '../util/validators'
import { useNavigate } from 'react-router-dom';

function ResetPassword() {

    const history = useNavigate();

    const [searchParams] = useSearchParams();

    const [token, setToken] = useState();


    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
        inputHandler] = useForm({

        email: {
            value: '',
            isValid: false
        },
        password: {
            value: '',
            isValid: false
        }
    }, false);

    const authSubmitHandler = async event => {
        event.preventDefault();
        

        try {

            const data = JSON.stringify({
                password: formState.inputs.password.value,
                password_confirmation: formState.inputs.confirmPassword.value,
                token: token
            })

          await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/password/reset`, 'POST', data, {'Content-Type': 'application/json'});
            
            history("/login")

        } catch (err) {
            
            console.log(error)
        }
    };

    useEffect(() => {
        setToken(searchParams.get('token'));

    }, [searchParams])


    return ( 
    <> 
    <div className = 'grid grid-cols-1 lg:grid-cols-3 relative pb-16 md:pb-16 lg:pb-32 h-screen md:h-full' > 
    <img
        src={images.FormImage[0]}
        alt={images.FormImage[1]}
        className='hidden lg:block'/> 
    
    <div className='mt-8 col-span-2 justify-center px-4 md:px-16 xl:px-32'>
    <div className = 'mt-8 md:mt-16 mb-10 ' > 
    <h1 className='text__heading text-center md:text-left'>Reset Password</h1> 
    <p className = 'text-base opacity-50 mt-2 text-center md:text-left' > Your new password must be different<br/> from previous used passwords </p>
    </div> 
     <form className='w-4/5 md:w-4/5' onSubmit={authSubmitHandler}>
                <div className='grid grid-cols-1 gap-8 md:w-4/5'>
                    <div>
                    <Input
                        type="password"
                        placeholder=''
                        label="Password"
                        id="password"
                        validators={[VALIDATOR_MINLENGTH(8)]}
                        errorText="Please enter more 8 characters"
                        onInput={inputHandler}/>

                    <p className="text-sm opacity-50 mt-3">Must be atleast 8 characters</p>
                    </div>

                    <div>
                    <Input
                        type="password"
                        placeholder=''
                        label="Confirm Password"
                        id="confirmPassword"
                        validators={[VALIDATOR_MINLENGTH(8)]}
                        errorText="Please enter more 8 characters"
                        onInput={inputHandler}/>

                    <p className="text-sm opacity-50 mt-3">Both passwords must match</p>
                    </div>
                </div>

                <div className='flex justify-start items-center mt-8 md:mt-12'>
                    <input
                        type='submit'
                        className={`form__submit button__padding text-white mr-2 w-full md:w-4/5 `}
                        value="Reset Password"/> {isLoading && <Loader/>}
                </div>

            </form>
    </div>
    

            <img
                src={images.CircleImage[0]}
                alt={images.CircleImage[1]}
                className='absolute -bottom-10 right-0 -z-10'/> 
                
    </div> 
    </>
    )
}

export default ResetPassword