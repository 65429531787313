import React,{useContext} from "react";

// import {images} from "../../assests/index";

import {AuthContext} from '../../context/auth-context';
import { DataContext } from "../../pages/dashboard/context/DataContext";

import Loader from "../../UI_Elements/loader/Loader";
import Avatar from '../../pages/dashboard/UI_Elements/Avatar/Avatar'

import * as Icons from "react-icons/ti";

import { createPopper } from "@popperjs/core";
import { useHttpClient } from "../../hooks/less-http-hook";

const UserDropdown = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const auth = useContext(AuthContext);
  const data = useContext(DataContext);

  const {isLoading, error, sendRequest} = useHttpClient();

  const logoutHandler = async(event) =>{
    event.preventDefault();

    try {
      await
      sendRequest(`${process.env.REACT_APP_API_CALL}/customers/logout
      `, 'POST', null,{'Content-Type': 'application/json', Authorization: 'Bearer '+auth.token});

      auth.logout()
      

  } catch (err) {
      console.log(error)
  }

    
  }
  return (
    <>
      <a
        className="text-slate-500 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex justiy-center">
          <span className="w-12 h-12 text-sm p-1 border-slate-300 border-2 text-white bg-white inline-flex items-center justify-center rounded-full">
            {/* <img
              alt="..."
              className="w-full rounded-full align-middle border-none shadow-lg"
              src={images.logo[0]}
            /> */}

            <Avatar src={data.image} alt="avatar" name={data.firstname} className={`w-full rounded-full align-middle border-none shadow-lg`} profileClass="w-full rounded-full align-middle border-none shadow-lg  text-primary-red" red textCenter/> 
          </span>
          <Icons.TiArrowSortedDown className='ml-1 text-base rounded-md' style={{color:'#696974'}}/>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <button
          
          className={
            "text-sm py-2 px-2 pl-4 text-left font-normal block w-full whitespace-nowrap bg-transparent text-primary-black hover:bg-primary-red hover:text-white"
          }
          onClick={logoutHandler}
        >
          Logout
        </button>{isLoading && <Loader/>}
        
      </div>
    </>
  );
};

export default UserDropdown;
