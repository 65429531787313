import React from 'react'

import Button from '../../../UI_Elements/Button/Button'

function cover() {
    return (
        <div className='h-95vh w-screen relative cover__background'>
            <div className='absolute top__20 p-8 md:pl-16 xl:pl-32 text-white'>
                <h1  className='mb-8 text-2xl sm:text-3xl md:text-5xl font-bold heading__primary'>Enjoy coverage<br/>Everywhere you go</h1>
                <p className='mb-12 text-sm md:text-base w-4/4 md:w-2/4'>Leadfort insurance brokers is your one-stop shop for insurance services 
                    and resources. Our service delivery and lead time is second to none.</p>
                <Button to='/signup' colored>Get Started</Button>
            </div>
        </div>
    )
}

export default cover
