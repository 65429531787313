import React, {useCallback, useContext, useEffect, useState} from 'react';

import {VALIDATOR_REQUIRE} from '../../../util/validators';
import {useHttpClient} from '../hooks/dashboard-http-hook';
import {AuthContext} from '../../../context/auth-context';



import HOC from '../HOC/HOC'
import Loader from '../UI_Elements/Loader/Loader'

import PolicyList from '../Components/PolicyList';
import PolicyMotor from '../modals/PolicyMotor';


function InsurancesPolicies() {

    const auth = useContext(AuthContext)

    const [policy,
        setPolicy] = useState(null);
    const [loadedPolicies,
        setLoadedPolicies] = useState();

    const[showMotor, setShowMotor] = useState(false)


    const {isLoading, error, sendRequest} = useHttpClient();

    useEffect(() => {
        const fetchPolicies = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/policy/list`, "GET", null, {Authorization: 'Bearer '+auth.token});
                setLoadedPolicies(responseData.policy);

            } catch (err) {
                console.log(error)
            }
        };
        fetchPolicies();
    }, [sendRequest, error, auth]);

    const policyHandler = useCallback((value) => {

        if(value.value){
            setPolicy(value)
        }
        

    }, [])

    const submitHandler = async(event) => {
        event.preventDefault(); 


        // try {
        //     const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/insurance/buy/one`, "POST", JSON.stringify({
        //         policy_id: policy.id
        //     }), {Authorization: 'Bearer '+auth.token});
        //     setLoadedPolicies(responseData.policy);
        //     console.log(responseData)

        // } catch (err) {
        //     console.log(error)
        // }

        if(policy.value.includes("Motor")){
            setShowMotor(!showMotor)
        }

    }

    const onCancelHandler = ()=>{
        setShowMotor(!showMotor)
    }

    return (
        <HOC>
                <div className='lg:w-4/5 xl:w-3/5 bg-white  shadow rounded-lg'>
                <div className='bg-slate-200 h-24 rounded-t-lg'></div>
                {isLoading && <Loader text="" title="Loading..."/>}

                {!isLoading && loadedPolicies && (
                    <form
                        className='grid grid-cols-1 md:grid-cols-2 px-2 md:px-12 py-4 gap-8'
                        onSubmit={submitHandler}>
                        <PolicyList
                            options={loadedPolicies}
                            label="Select Insurance Policy"
                            id="policy"
                            placeholder='Search'
                            onInput={policyHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            classNameContainer=" md:col-span-2 "/>
                        
                        
                        
                        <div className="flex justify-content items-center w-full mx-auto md:col-span-2">
                            <input
                                type="submit"
                                className={`form__submit py-3 px-8 md:px-16 lg:px-24 text-white mx-auto ${( policy === null) && 'form__submit__disabled'}`} 
                                value="See Insurers" 
                            disabled={policy === null}    />
                        </div>

                        
                    </form>
                )}


                <PolicyMotor show={showMotor} onCancel={onCancelHandler} policy={policy}/>
            </div>


            
            
        </HOC>
    )
}

export default InsurancesPolicies
