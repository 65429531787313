import React from 'react';

import PaymentTable from '../Components/Payments/PaymentTable';

function Payments() {
  return (
      <PaymentTable/>
  )
}

export default Payments;
