import React from 'react'

import Cover from '../cover'
import About from '../about'
import Why from '../Why'
import Testimonies from '../Testimonies'
import Coverage from '../Coverage'
import Policies from '../Policies'

function Home() {
  return (
    <>
        <Cover/>
        <About/>
        <Policies/>
        <Why/>
        <Testimonies/>
        <Coverage/>
    </>
  )
}

export default Home