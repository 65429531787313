import React from 'react'
import TitleHOC from './TitleHOC'
import ItemLi from './ItemLi'

function Motor() {
  return (
    <div>
        <div className="mb-8">
            <TitleHOC 
            title="Motor Insurance" 
            image='https://res.cloudinary.com/devgodfrey/image/upload/v1688506554/motor-bg.png'/>
        </div>

        <div data-aos="fade-up" 
        className={`relative px-8 md:px-16 xl:px-32 py-3 md:py-8 xl:py-0 
        overflow-hidden md:mt-3 xl:mt-0`}>
            <h1 className="text-bg-black text-3xl font-semibold">Motor Insurance</h1>
            <p className="text-bg-black my-6 mb-8 font-light text-lg">
            This form of insurance compensates the insured for losses resulting from the use of the vehicle. 
            Such losses can be in any or a combination of any of the scenario stated below:
            </p>

            <div>
                <ItemLi text="Bodily injury and/or death to third party"/>
                <ItemLi text="Damage to third party property"/>
                <ItemLi text="Damage to the insured vehicle"/>
                <ItemLi text="Loss or damage to the insured vehicles resulting from theft or attempt threat"/>
            </div>

            <p className="text-bg-black my-8 font-light text-lg">
                Cover can be extended to loss of use, excess by back, riot, strike and civil commotion and the like
            </p>

            <h3 className="text-bg-black opacity-50 font-bold mt-8 md:mt-12 mb-3 text-lg">Classification of Motor Risks:</h3>
            <p className='font-light mb-8'>
                Depending on the use to which the vehicle is put, the following classes of motor risks can be found.
            </p>

            <div>
                <ItemLi text="Private motor"/>
                <ItemLi text="Commercial vehicles"/>
                <ItemLi text="Private and public hire"/>
                <ItemLi text="Passenger carrying vehicles"/>
                <ItemLi text="Goods carrying vehicles."/>
                <ItemLi text="Agricultural and forestry vehicles"/>
                <ItemLi text="Special types of vehicles"/>
                <ItemLi text="Motor trade"/>
                <ItemLi text="Motor cycles"/>
            </div>

            <h3 className="text-bg-black opacity-50 font-bold mt-8 md:mt-12 mb-3 text-lg">Types of Cover</h3>
            <p className='font-light mb-8'>
                The following covers are available in the Nigerian insurance market for any of the class of risk stated above.
            </p>

            <div>
                <ItemLi text="The road traffic Act only cover"/>
                <ItemLi text="The road traffic Act only cover"/>
                <ItemLi text="Third party, fire and theft cover"/>
                <ItemLi text="Comprehensive cover."/>
            </div>

            <p className="text-bg-black my-6 font-light text-lg">
                Cover can be extended to loss of use, excess by back, riot, strike and civil commotion and the like
            </p>

            <div>
                <ItemLi text="Internal risk policy"/>
                <ItemLi text="Road risk policy"/>
            </div>
        </div>

    </div>
  )
}

export default Motor