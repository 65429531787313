import React from 'react'

import {images} from '../../../assests/index'
import {whyItems} from '../UIItems/whyItems'
import HOC from '../HOC/HOC'

import '../landing.css'

function Why() {
    return (
        <div className='relative pb-8'>
            <HOC>
                <div>
                    <div>
                        <p className='text-sm mb-2'>OUR EDGE</p>
                        <h1 className='text__heading text-left'>Why Leadfort Insurance?</h1>
                    </div>
                    <div className='w-full mt-12'>
                        <img
                            src={images.leadfortInsurance[0]}
                            alt={images.leadfortInsurance[1]}
                            className='mx-auto object-fill mb-16'/> 

                        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-2 gap-x-4 lg:gap-y-8'>
                            {whyItems.map((item) => {
                                return (
                                    <div className='w-full text-white mb-8 lg:mb-0 mx-auto' key={item.id}>
                                        <h3 className='text-center md:text-left text__subtitle font-bold'>{item.title}</h3>
                                        <p className='text-center md:text-left lg:w-5/6 mt-2 lg:mt-4'>{item.text}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </HOC>
            <div className='why__background absolute bottom-0 left-0'></div>
        </div>
    )
}

export default Why
