import React from 'react'
import { images } from '../../../../assests'
import HOC from '../../HOC/HOC'
import StarCard from './StarCard'

function Mission() {
  return (
    <HOC>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 lg:gap-12'>
            <div className='box-border-red p-8 py-10 pb-6 rounded-lg'>
                <div className='flex items-center'>
                    <img src={images.chessIcon[0]} alt={images.chessIcon[1]}/>
                    <h2 className='text-xl md:text-2xl lg:text-3xl my-auto text-primary-black font-bold'>
                        Our mission
                    </h2>
                </div>

                <p className='font-light mt-4'>To create a global insurance broking and risk management 
                consulting organization 
                    focused on providing quality insurance and risk management services with integrity, 
                    impact, and objectivity that will generate outstanding returns for all stakeholders.
                </p>
            </div>

            <div className='box-border-red p-8 py-10 rounded-lg'>
                <div className='flex items-center'>
                    <img src={images.glassesIcon[0]} alt={images.glassesIcon[1]}/>
                    <h2 className='ml-2 leading-tight text-xl md:text-2xl lg:text-3xl my-auto text-primary-black font-bold'>
                        Our vision
                    </h2>
                </div>

                <p className='font-light mt-4'>
                    To be the pre-eminent insurance broking firm in the Nigerian Insurance Market.
                </p>
            </div>

        </div>

        <div className="mt-24 md:mt-32 ">
            <h2 className='ml-2 mb-8 text-center leading-tight text-2xl lg:text-3xl text-primary-black font-extrabold'>
                Core values
            </h2>

            <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 px-4 xl:px-24">
                <StarCard image={images.starGreen[0]} text="Professionalism"/>
                <StarCard image={images.starRed[0]} text="EXcellence"/>
                <StarCard image={images.starBlue[0]} text="mutual respect"/>
                <StarCard image={images.starGreen[0]} text="trust"/>
            </div>

            <div className="flex flex-col md:flex-row justify-center items-center bg-bg-black mt-12 w-fit gap-6 mx-auto p-4 py-6">
                <div className='flex items-center gap-6'>
                    <StarCard image={images.starGreen[0]}/>
                    <StarCard image={images.starRed[0]} />
                </div>
                <p className="text-white font-semibold text-center text-xl md:text-2xl">
                    To ensure a value-driven brand.
                </p>
                <div className='flex items-center gap-6'>
                    <StarCard image={images.starBlue[0]} />
                    <StarCard image={images.starGreen[0]}/>
                </div>
            </div>
        </div>

        
    </HOC>
  )
}

export default Mission