
export const whyItems = [
    {
        id:1,
        title: 'Choice of insurance company',
        text: 'With our vast experience and reputation, we gurantee the security of our client’s risk'
    },
    {
        id:3,
        title: 'Review of insurances',
        text: 'A periodic review of our clients insurances to ensure full coverage at all times.'
    },
    {
        id:2,
        title: 'Claims administration',
        text: 'Our clients claims are promptly settled by making prompt follow-ups and early resolution of conflict if any'
    },
    
    {
        id:4,
        title: 'Cost management',
        text: 'We provide reduced rate and discounts with a view to bring down insurance costs for our clients'
    }
]
