import React, {useContext, useEffect, useState} from 'react';

import axios from 'axios'

import {useForm} from '../../../../hooks/form-hook';

import * as Icons from 'react-icons/fi'

import {VALIDATOR_EMAIL, VALIDATOR_REQUIRE} from '../../../../util/validators';

import Input from '../../../../UI_Elements/Input/Input';

import PhoneInput from 'react-phone-number-input';
import { useHttpClient } from '../../hooks/dashboard-http-hook';
import Loader from '../../../../UI_Elements/loader/Loader';
import { AuthContext } from '../../../../context/auth-context';
import { DataContext } from '../../context/DataContext';
import Avatar from '../../UI_Elements/Avatar/Avatar';

function Account(props) {

    const auth = useContext(AuthContext);
    const data = useContext(DataContext);

    const [loadedData, setLoadedData] = useState(null);

    const [phoneValue,
        setphoneValue] = useState(null);

    const [profileImage,
            setProfileImage] = useState(null);

    const [profileFile, setProfileFile] = useState(null)

    const [isLoadingProfile, setisLoadingProfile] = useState(false)

    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
        inputHandler, setFormData] = useForm({
            firstname: {
                value: '',
                isValid: false
            },  
            lastname: {
                value: '',
                isValid: false
            },
            phonenumber: {
                value: '',
                isValid: false
            },
            email: {
                value: '',
                isValid: false
            }

    }, false);

    useEffect(() => {
        const fetchData = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/getData`, "GET", null, {Authorization: 'Bearer '+auth.token});
                setLoadedData(responseData);
                
                setFormData(
                    {
                      firstname: {
                        value: responseData.customer.firstname,
                        isValid: true
                      },
                      lastname: {
                        value: responseData.customer.lastname,
                        isValid: true
                      },
                      email: {
                        value: responseData.customer.email,
                        isValid: true
                      },
                      phonenumber: {
                        value: responseData.customer.phone_number,
                        isValid: true
                      }
                    },
                    true
                  );

                  setphoneValue(responseData.customer.phone_number)

                  setProfileImage(responseData.customer.image)

                  

            } catch (err) {
                console.log(error)
            }
        };
        fetchData();
    }, [sendRequest, error, setFormData, auth]);


    const readerHandler = (file) =>{
        const reader = new FileReader();
        reader.onload = (e) => {
            setProfileImage(e.target.result)
        }

        reader.readAsDataURL(file)
    }

    const profileHandler = async(incomingFile) =>{
        const file = incomingFile
        

        try{
            const formData = new FormData();
                    
            formData.append("file", file);
            formData.append('upload_preset', 'leadfort-claims');
    
            await axios.post("https://api.cloudinary.com/v1_1/devgodfrey/image/upload",formData).then(async(res) =>{
                
                const imageData = new FormData()   

                imageData.append("image",res.data.secure_url)

                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/uploadImage`, "POST", imageData, {Authorization: 'Bearer '+auth.token});
                if(responseData){
                    data.UpdateData(null, null, null, null, res.data.secure_url)
                    setisLoadingProfile(false)
                }  
                

            })
        }
        catch(e) {
            console.log(e)
            setisLoadingProfile(false)
        }

        

    }

    const accountSubmitHandler = async e => {
        e.preventDefault();

        if(profileFile){
            setisLoadingProfile(true)
            profileHandler(profileFile);
            
        }

        try {

            const data = JSON.stringify({
                firstname: formState.inputs.firstname.value,
                lastname: formState.inputs.lastname.value,
                email: formState.inputs.email.value,
                phone_number: phoneValue
            })
            
            const responseData = await
            sendRequest(`${process.env.REACT_APP_API_CALL}/customers/editprofile
            `, 'POST', data,{'Content-Type': 'application/json', Authorization: 'Bearer '+auth.token});


            props.data.UpdateData(responseData.admin.firstname, responseData.admin.lastname, responseData.admin.email, responseData.admin.phone_number,null)
            


            
            

        } catch (err) {
            console.log(error)
        }
    }
    return (
        <div className='p-12 bg-white w-full'>
        {loadedData && 
            <div>
            <div className='w-full mx-auto mb-8 flex justify-center items-center '>
                    <div className='relative'>  
                        <Avatar src={profileImage} alt="account profile" name={loadedData.customer.firstname} className="mx-auto w-20 h-20 rounded-full " profileClass="rounded-full w-20 h-20 bg-primary-red flex justify-center item-center"/>
                        <label htmlFor='profile' className='bg-primary-black p-2 rounded-full absolute bottom-2 -right-1 dash_border_white z-20 cursor-pointer'>
                            <Icons.FiCamera className=' rounded-full text-base text-white' /> 
                        </label>
                    </div>
                    <input type="file"  
                className='w-1 h-1 opacity-0 ' accept='image/*' id='profile' onChange={(e) => {
                    setProfileFile(e.target.files[0]); 
                    readerHandler(e.target.files[0])
                }}/>

            </div>
            <div>
                <form onSubmit={accountSubmitHandler} >
                   
                    <div className='grid lg:grid-cols-2 gap-8 '>
                    <Input
                        label="First Name"
                        onInput={inputHandler}
                        initialValue={loadedData.customer.firstname}
                        initialValid={true}
                        validators={[VALIDATOR_REQUIRE()]}
                        id="firstname"
                        placeholder="First Name"
                        type="text"
                        className="dash-p-4" 
                        element="input"/>
                    <Input
                        label="Last Name"
                        onInput={inputHandler}
                        initialValue={loadedData.customer.lastname}
                        initialValid={true}
                        id="lastname"
                        placeholder="Last Name"
                        validators={[VALIDATOR_REQUIRE()]}
                        type="text"
                        className="dash-p-4"
                        element="input"/>
                    <Input
                        label="Email Address"
                        onInput={inputHandler}
                        initialValue={loadedData.customer.email}
                        initialValid={true}
                        validators={[VALIDATOR_EMAIL()]}
                        id="emailaddress"
                        placeholder="Email Address"
                        type="text"
                        className="dash-p-4"
                        element="input"/>
                    <div className='flex flex-col'>
                        <label className='text-sm mb-2'>Phone Number</label>
                        <div className='border-2 p-2 rounded-sm border-slate-300 bg-white input_button'>
                            <PhoneInput
                                className="border-slate-300"
                                defaultCountry="NG"
                                withCountryCallingCode
                                placeholder="Enter phone number"
                                value={loadedData.customer.phone_number || phoneValue}
                                onChange={setphoneValue}/>
                        </div>
                    </div>

                    <div className='my-8 mx-auto flex justify-start items-center lg:col-span-2'>
                        <input
                            type='submit'
                            className={`form__submit py-3 px-8 md:px-16 lg:px-24 text-white mr-2 `}
                            value="Save Changes" 
                            /> {(isLoading || isLoadingProfile) && <Loader/>}
                    </div>

                    </div>
                    
                </form>
            </div>
            </div>
            }
        </div>
    )
}

export default Account;
