import React, { useState } from 'react'
import ImagesModal from './components/ImagesModal'

function CarInsuranceItem(props) {
    
    const [showImage, setShowImage] = useState(false)

    const [displayImage, setDisplayImage] = useState(null)

    const cancelImageHandler = () =>{
        setShowImage(!showImage)
    }

  return (
        <>
            <ImagesModal show={showImage} onCancel={cancelImageHandler} img={displayImage}/>
            <div className='md:col-span-3 px-4 py-2 pb-16 rounded-xl shadow bg-white'>
                
                <h1 className="pb-2 dash__color w-full dash_border-b border-black">Asset Description</h1>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2'>
                    <div className='py-4'>
                        <h3 className='dash__subtitle text-left mb-4'>Vehicle Make</h3>
                        <p className='dash__text text-left text-sm'>{props.vehicleMake}</p>
                    </div>
                    <div className='py-4'>
                        <h3 className='dash__subtitle text-left mb-4'>Vehicle Type</h3>
                        <p className='dash__text text-left text-sm'>{props.vehicleType}</p>
                    </div>
                    <div className='py-4'>
                        <h3 className='dash__subtitle text-left mb-4'>Vehicle Color</h3>
                        <p className='dash__text text-left text-sm'>{props.vehicleColor}</p>
                    </div>
                    <div className='py-4'>
                        <h3 className='dash__subtitle text-left mb-4'>Registration Number</h3>
                        <p className='dash__text text-left text-sm'>{props.registrationNumber}</p>
                    </div>
                    <div className='py-4'>
                        <h3 className='dash__subtitle text-left mb-4'>Chasis Number</h3>
                        <p className='dash__text text-left text-sm'>{props.chasisNumber}</p>
                    </div>
                    <div className='py-4'>
                        <h3 className='dash__subtitle text-left mb-4'>Engine Number (Optional)</h3>
                        <p className='dash__text text-left text-sm'>{props.engineNumber}</p>
                    </div>
                    <div className='py-4'>
                        <h3 className='dash__subtitle text-left mb-4'>Use of Vehicle</h3>
                        <p className='dash__text text-left text-sm'>{props.useOfVehicle}</p>
                    </div>
                    <div className='py-4'>
                        <h3 className='dash__subtitle text-left mb-4'>Type of Cover</h3>
                        <p className='dash__text text-left text-sm'>{props.typeOfCover}</p>
                    </div>
                    
                </div>

            </div>

            <div className='md:col-span-3 px-4 py-2 pb-16 rounded-xl shadow bg-white'>
                    <h1 className="pb-2 dash__color w-full dash_border-b border-black mb-4">Photos Of Vehicle</h1>
                        <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 '>

                            {
                                props.photo_of_vehicle && props.photo_of_vehicle.split(",").map((img, index) => {
                                    return (
                                        <div key={index} onClick={()=>{
                                            setDisplayImage(img)

                                            cancelImageHandler()
                                        }}>
                                            <img src={img} alt={index} className="w-16 h-16 object-contain "/>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    
                </div>

                <div className='md:col-span-3 px-4 py-2 pb-16 rounded-xl shadow bg-white'>
                    <h1 className="pb-2 dash__color w-full dash_border-b border-black mb-4">Photos Of Driver License</h1>
                        <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 '>

                            {
                                props.drivers_license && props.drivers_license.split(",").map((img, index) => {
                                    return (
                                        <div key={index} onClick={()=>{
                                            setDisplayImage(img)

                                            cancelImageHandler()
                                        }}> 
                                            <img src={img} alt={index} className="w-16 h-16 object-contain "/>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    
                </div>

        </>

  )
}

export default CarInsuranceItem