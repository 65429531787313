import React, {useState, useRef, useEffect} from 'react';


import * as Icons from "react-icons/ti";

import * as IconT from 'react-icons/bi'

import {createPopper} from "@popperjs/core";




function Select(props) {
    const [policyValue,
        setPolicyValue] = useState("")

      const {onInput } = props;

  useEffect(() => {
    
    onInput(policyValue)
  }, [policyValue, onInput]);

  useEffect(() => {
    
    document.addEventListener("click", hideOnClickOutside, true)
  }, [])

    const [query, setQuery] = useState("");

    const [dropdownPopoverShow,
        setDropdownPopoverShow] = useState(false);
    const btnDropdownRef = useRef();
    const popoverDropdownRef = useRef();

    const openDropdownPopover = () => {
        createPopper(btnDropdownRef.current, popoverDropdownRef.current, {placement: "bottom-start"});
        setDropdownPopoverShow(true);
    };

    const closeDropdownPopover = () => {
        setDropdownPopoverShow(false);
    };

    const hideOnClickOutside = (e) => {
        if( btnDropdownRef.current && !btnDropdownRef.current.contains(e.target) && popoverDropdownRef.current && !popoverDropdownRef.current.contains(e.target) ) {
            setDropdownPopoverShow(false);
        }
      }
    

    const dataFilter = () => {
        
        if(query === "") {
            return props.options.sort();
        }

        return props.options.sort().filter((option) => {
            const optionName = option.toString().toLowerCase();
            return optionName.includes(query.toString())
        })
    }


    return (
        
        <div className={`flex flex-col`}>
            <label className='text-sm mb-2' htmlFor='Policy'>{props.label}</label>
            <button className="relative px-1 border-2 border-slate-300 "
            ref={btnDropdownRef}
            onClick={(e) => {
                e.preventDefault();
                dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
              }}>

                <div >  
                    <input
                        type="text"
                        id={props.id}
                        className='outline-none border-none text-left text-sm lg:text-base '
                        value={props.initialValue || policyValue} 
                        placeholder={props.placeholder}
                        readOnly
                        />
                </div>

                <Icons.TiArrowSortedDown
                    className='ml-1 text-base rounded-md text-primary-black absolute top-1/3 right-2'/>

            </button>

            <div
                ref={popoverDropdownRef}
                className={(dropdownPopoverShow
                ? "block "
                : "hidden ") + "bg-white text-base z-50 float-left py-2 list-none text-left max-h-48 rounded overflow-y-scroll shadow-md mi" +
                    "n-w-48"}>

            {props.search && <div className='flex items-center rounded-full shadow-sm m-2 p-2 border-slate-300 border-2'>
                <input type="text" className='text-sm outline-none search border-0' placeholder='search...' id='search_select' onInput={(e) => {
                e.preventDefault();
                setPolicyValue(e.target.value);
                setQuery(e.target.value);
            }} />
                <label htmlFor='search_select'>
                    <IconT.BiSearch className='text-slate-500'/>
                </label>
            </div>}

            {
                dataFilter().map((post, index) => {
                    return (
                        <button 
                        key={index}
                        className={
                            "text-sm py-2 px-2 pl-4 text-left font-normal block w-full whitespace-nowrap bg-transparent text-primary-black hover:bg-primary-red hover:text-white"
                        }
                        onClick={(e) => {
                            e.preventDefault();
                            setPolicyValue(post);
                            closeDropdownPopover()
                        }}
                        >
                            {post}
                        </button>  
                    )
                })
            }
             

            </div>
        </div>
    )
}

export default Select;
