import React from 'react'

import HOC from '../HOC/HOC';

import {aboutItems} from '../UIItems/aboutItems'

import {images} from '../../../assests/index'

function about() {
    return (
        <HOC>
            
            <img src={images.FrontCircle[0]} alt={images.FrontCircle[1]} className='absolute top__negative'/>
                <div className=''>
                    <h1 className='text__heading text-center'>Our Approach to Keeping You Insured</h1>
                    <p className='text__paragraph text-center mx-auto mt-4'>At leadfort, we deploy every expertise at our disposal in<br/> carryout the job for which we are engaged</p>
                </div>
                <div className='flex justify-center items-center flex-col lg:flex-row'>
                    {aboutItems.map((item) =>{
                        return (
                            <div className={`mt-10`} key={item.primarycolor}>
                                
                                <div style={{backgroundColor:`${item.primarycolor}10`, borderRadius:'50%'}} className='w-12 h-12 mx-auto flex justify-center items-center mb-8 animate-bounce'>
                                    <div style={{backgroundColor:item.primarycolor, zIndex:'4', borderRadius:'50%'}} className="w-2 h-2 rounded-full mx-auto animate-bounce  "></div>
                                </div>
                                <h4 className="text__subtitle font-bold text-center ">{item.title}</h4>
                                <p className= {`text-base text-center pt-4 px-6  ${item.bordercolor}`}>{item.text}</p>
                            </div>
                        )
                    })}
                </div>
           
        </HOC>
    )
}

export default about
