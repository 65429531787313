import React from 'react'
import TitleHOC from './TitleHOC'
import ItemLi from './ItemLi'

function Marine() {
  return (
    <div>
        <div className="mb-8">
            <TitleHOC 
            title="Marine Insurance (Cargo)" 
            image='https://res.cloudinary.com/devgodfrey/image/upload/v1688500161/marine-bg.png'/>
        </div>

        <div data-aos="fade-up" 
        className={`relative px-8 md:px-16 xl:px-32 py-3 md:py-8 xl:py-0 
        overflow-hidden md:mt-3 xl:mt-0`}>
            <h1 className="text-bg-black text-3xl font-semibold">Marine Insurance (Cargo)</h1>
            <p className="text-bg-black my-6 mb-8 font-light text-lg">
                This covers the risk of loss, damages, expense and liability to your goods during transportation 
                as cargo from one place to another. The process of transportation includes airfreight, ocean 
                freight and overland carriage. The marine cargo insurance is to indemnify the cargo owners and/or 
                the financiers against financial loss arising as a result of physical loss, damage, expenses 
                incurred or liability from the transportation process. Loss can arise from the perils of the sea 
                such as rough weather, sinking, or as a result of overturning collision and for theft from 
                overland transport.
            </p>

            <h3 className="text-bg-black opacity-50 font-bold mb-3 text-lg">Types of Cover Available</h3>
            <p className='font-light mb-3'>
                There are three (3) types of cover which are provided under what is termed as institute 
                cargo clause. Briefly they are:
            </p>

            <div className="mb-4">
                <ItemLi text="Institute Cargo Clause A"/>
                <ItemLi text="Institute Cargo Clause B"/>
                <ItemLi text="Institute Cargo Clause C"/>
            </div>

            <h3 className="text-bg-black opacity-50 font-bold my-3 mt-6 text-lg">Required</h3>
            <div>
                <ItemLi text="Performa invoice"/>
                <ItemLi text="Form M"/>
            </div>
        </div>

    </div>
  )
}

export default Marine