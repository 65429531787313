import React, { useRef } from 'react'
import Slider from "react-slick";

import * as Icons from "react-icons/bs";

import HOC from '../../HOC/HOC';
import { boardItems } from '../../UIItems/boardItems';
import BoardItem from '../../Components/BoardItem';

export const Board = () => {
    const sliderRef = useRef(null);

    const settings = {
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        adaptiveHeight:true,
        adaptiveWidth:true,
        arrows:true,
        // centerMode: true,
        dots:true,
    
        responsive: [
          {
            breakpoint: 1250,
            settings: {
              arrows: true,
              centerPadding: '30px',
              slidesToShow: 3,
            }
          },
            {
              breakpoint: 900,
              settings: {
                arrows: true,
                centerPadding: '30px',
                slidesToShow: 2,
                
    
              }
            },
            {
              breakpoint: 480,
              settings: {
                arrows: false,
                centerPadding: '20px',
                slidesToShow: 1,
                
              }
            }
          ]
      };
    return (
        <HOC>
            <div className='mt-12'>
                <div className='flex justify-between items-center'>
                  <h1 className='text__heading text-left'>Our Board</h1>
                    <div className='md:flex justify-center items-center mr-8 hidden'>
                      <Icons.BsArrowLeftCircle className='mr-8 md:text-4xl cursor-pointer' onClick={() => sliderRef.current.slickPrev()} />
                      <Icons.BsArrowRightCircle className='md:text-4xl cursor-pointer' onClick={() => sliderRef.current.slickNext()}/>
                    </div>
                </div>
                <div className='my-8 md:my-12 lg:my-16'>
                    <Slider ref={sliderRef} {...settings}>
                      {boardItems.map((item,index) => {
                        return(
                          <div key={index} >
                            <BoardItem image={item.image} text={item.text} />
                          </div>
                        )
                      })} 
                    </Slider>
                </div>
            </div>

        </HOC>
    )
}