import React, {useContext, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import Button from '../UI_Elements/Button/Button'
import Input from '../UI_Elements/Input/Input'
import Google from './components/Google'


import {VALIDATOR_EMAIL, VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE} from '../util/validators';
import {useForm} from '../hooks/form-hook';
import {useHttpClient} from '../hooks/http-hook';
import {AuthContext} from '../context/auth-context';
import {images} from '../assests/index'

import './auth.css'
import Loader from '../UI_Elements/loader/Loader';
import PhoneInput from 'react-phone-number-input';

import {signInWithGoogle} from './Firebase/FirebaseConfig'

function Signup(props) {
    const history = useNavigate()
    const auth = useContext(AuthContext);
    const [phoneValue,
        setphoneValue] = useState()

    const [checked, setChecked] = useState(false)

    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
        inputHandler] = useForm({
        firstname: {
            value: '',
            isValid: false
        },
        lastname: {
            value: '',
            isValid: false
        },
        phonenumber: {
            value: '',
            isValid: false
        },
        email: {
            value: '',
            isValid: false
        },
        password: {
            value: '',
            isValid: false
        },
        confirmPassword: {
            value: '',
            isValid: false
        }
    }, false);

    const authSubmitHandler = async event => {
        event.preventDefault();
        console.log(formState.inputs.confirmPassword.value)
        if(!phoneValue){
            let swalAlert = await Swal.fire('Error', "Please kindly enter a valid phone number", 'error');
                if (swalAlert.isConfirmed) {
                    return
                } 
        }

        if(formState.inputs.password.value !== formState.inputs.confirmPassword.value){
            let swalAlert = await Swal.fire('Error', "Please kindly enter a matching password", 'error');
                if (swalAlert.isConfirmed) {
                    return
                } 
        }

        const phonevalue = phoneValue.substring(1);   

        try {

            const data = JSON.stringify({
                firstname: formState.inputs.firstname.value,
                lastname: formState.inputs.lastname.value,
                email: formState.inputs.email.value,
                phone_number: phonevalue,
                password: formState.inputs.password.value,
                password_confirmation: formState.inputs.confirmPassword.value
            })
            const responseData = await
            sendRequest(`${process.env.REACT_APP_API_CALL}/customers/create`, 'POST', data,{'Content-Type': 'application/json'});
            auth.login(responseData.customer.authId);
            history("/login");

        } catch (err) {
            console.log(error)
            
        }
    };

    const googleSubmitHandler = async event => {
        event.preventDefault();
        

        await signInWithGoogle().then(
            (response)=>{
                const fetch = async() => {
                    try {

                        const data = JSON.stringify({
                            firstname: response._tokenResponse.firstName,
                            lastname: response._tokenResponse.lastName,
                            email: response._tokenResponse.email,
                            phone_number: response.user.phoneNumber,
                            google_id: response._tokenResponse.localId
                        })
                        const responseData =
                        await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/create/google`, 'POST', data,{'Content-Type': 'application/json'});
                        auth.login(responseData.customer.authId, responseData.token);
                        history("/dashboard")
            
                    } catch (err) {
                        console.log(err)
                        
                    }
                }

                fetch()
            }
        ).catch((error) => {
            console.log(error)
        })
        
    };

    const checkHandler = ()=>{
        setChecked(!checked)
    }

    return (
        <div
         className='grid grid-cols-1 lg:grid-cols-3 relative pb-16 md:pb-16'>
            <Button to='/'>
                <img
                    src={images.FormImage[0]}
                    alt={images.FormImage[1]}
                    className='hidden lg:block'/>
            </Button>
            <div data-aos="fade-left" className='mt-8 col-span-2 px-4 md:px-8 xl:px-32 lg:h-screen'>
                <div className='flex flex-col md:flex-row justify-between items-center'>
                    <Button to='/'>
                        <img
                            src={images.logo[0]}
                            alt={images.logo[1]}
                            className='w-32 object-contain mb-8 md:mb-0'/>
                    </Button>
                    <div className='flex flex-row justify-center items-center md:pr-8'>
                        <p className='text-sm opacity-50 mr-1'>I already have an account.</p>
                        <Button to='/login' transparent nopadding>Login</Button>
                    </div>
                </div>

                <div className='mt-8 md:mt-16 mb-10 '>
                    <h1 className='text__heading text-center md:text-left'>Get Started</h1>
                    <p className='text-base opacity-50 mt-2'>Create an account with us and enjoy your ride</p>
                </div>

                <form className='px-2 md:px-0' onSubmit={authSubmitHandler}>
                    <div className='grid grid-cols-1 md:grid-cols-2 md:pr-16 gap-8'>

                        <Input
                            type="text"
                            placeholder='Enter First Name'
                            label="First Name"
                            id="firstname"
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please enter a first name."
                            onInput={inputHandler}/>

                        <Input
                            type="text"
                            placeholder='Enter Last Name'
                            label="Last Name"
                            id="lastname"
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please enter a Last name."
                            onInput={inputHandler}/>

                        <Input
                            type="email"
                            placeholder='Enter Email Address'
                            label="Email Address"
                            id="email"
                            validators={[VALIDATOR_EMAIL()]}
                            errorText="Please enter a valid email"
                            onInput={inputHandler}/> 

                        <div className='flex flex-col'>
                            <label className='text-sm mb-2'>Phone Number</label>
                            <div className='border-2 px-2 rounded-sm border-slate-300 bg-white input_button'>
                                <PhoneInput
                                    className="border-slate-300"
                                    defaultCountry="NG"
                                    withCountryCallingCode
                                    placeholder="Enter phone number"
                                    value={phoneValue}
                                    onChange={setphoneValue}/>
                            </div>
                        </div>
                        <Input
                            type="password"
                            placeholder=''
                            label="Password"
                            id="password"
                            validators={[VALIDATOR_MINLENGTH(8)]}
                            errorText="Please enter more 8 characters"
                            onInput={inputHandler}/>

                        <Input
                            type="password"
                            placeholder=''
                            label="Confirm Password"
                            id="confirmPassword"
                            validators={[VALIDATOR_MINLENGTH(8)]}
                           
                            onInput={inputHandler}
                            errorText={formState.inputs.password.value !== formState.inputs.confirmPassword.value? "This password doesn't match":"This password doesn't match"}
                            />

                    </div>

                    <div className='mt-8 flex items-center'>
                        <input
                            type='checkbox'
                            className='type__checkbox mr-2'
                            id="terms" 
                            onChange={checkHandler}
                            />
                        <label className='flex justify-start items-center'>
                            <p className='text-sm opacity-50 mr-1'>I have read and accept the
                            </p>
                            <Button href='#' small textRed nopadding>terms and conditions.</Button>
                        </label>
                    </div>

                    <div className='my-8 flex justify-start items-center'>
                        <input
                            type='submit'
                            className={`form__submit button__padding text-white mr-2 ${!checked && 'form__submit__disabled'} `}
                            value="Create an Account" 
                            disabled={!checked} /> {isLoading && <Loader/>}
                    </div>

                    <Google onClick={googleSubmitHandler} text='Sign up with Google'/>
                    

                </form>
            </div>

            <img
                src={images.CircleImage[0]}
                alt={images.CircleImage[1]}
                className='absolute -bottom-10 right-0 -z-10'/>
        </div>
    )
}

export default Signup
