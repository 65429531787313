import React from 'react'

function BoardItem(props) {
    const {image, text} = props

  return (
    <div className='relative boardImage flex items-end w-64 h-96' 
    style={{
        background:`url(${image})`, 
    backgroundSize:"cover", backgroundRepeat:"no-repeat"}}>
    
        <p className="p-2 pl-3 pb-3 text-white z-2 w-5/6">{text}</p>
    </div>
  )
}

export default BoardItem