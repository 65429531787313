import React, {useEffect} from 'react';

import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';

import AOS from "aos";

import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-phone-number-input/style.css';
import './App.css'

import Landing from './pages/landing/Landing'
import Dashboard from './pages/dashboard/dashboard';
import Signup from './auth/Signup';
import Login from './auth/Login';
import ResetPassword from './auth/ResetPassword';


import { AuthContext } from './context/auth-context';
import { useAuth } from './hooks/auth-hook';
import ScrollToTop from './util/ScrollToTop';

function App() {

    useEffect(() => {
        AOS.init({duration: 2000});
        AOS.refresh();
        
    }, []);

    const { token, login, logout, userId } = useAuth();

    let routes;

    if (token) {
        
        routes = (
            <Routes>
                <Route path='/dashboard/*' element={<Dashboard/>} />
                <Route path="*" element={<Navigate to ="/dashboard" />}/>
            </Routes>
        );
    } else {
        routes = (
            <Routes>
                <Route path='/*' exact element={< Landing />}/>
                <Route path='/signup' exact element={< Signup />}/>
                <Route path='/login' exact element={< Login />}/>
                <Route path='/resetpassword' exact element={< ResetPassword />}/>
            </Routes>
        );
    }

    return (
        <AuthContext.Provider
            value={{
            isLoggedIn: !!token,
            token:token,
            userId: userId,
            login: login,
            logout: logout
        }}>
            
            <Router>
                <ScrollToTop>
                    {routes}
                </ScrollToTop>
            </Router>
            
        </AuthContext.Provider>
    );
}

export default App;