import React from 'react'
import Button from '../../../UI_Elements/Button/Button'

import {images} from '../../../assests/index'
import HOC from '../HOC/HOC'

function Coverage() {
    return (
        <HOC>
            <div className='relative coverage__background flex justify-center items-center px-4 md:px-0 py-16 md:py-28 w-full'>
                <img src={images.DotDesign[0]} alt={images.DotDesign[1]} className='absolute bottom-0 -left-1'/>
                <div className='text-white text-center z-10'>
                    <h1
                        className='pb-8 text-2xl sm:text-3xl md:text-5xl font-bold heading__primary '>Enjoy coverage<br/>Everywhere you go</h1>
                    <p className='pb-12 text-sm md:text-base w-4/4 md:w-3/4 mx-auto mb-4 '>Leadfort insurance
                        brokers is your one-stop shop for insurance services and resources. Our service
                        delivery and lead time is second to none.</p>
                    <Button to='/signup' textRed padding>Get Started</Button>
                </div>
            </div>
        </HOC>
    )
}

export default Coverage