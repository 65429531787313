import React, { useState, useEffect, useRef } from 'react';
import { policyItems } from './policyItems';
import { Link } from 'react-router-dom';

function PolicyNavsMobile() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const containerRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && 
    containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <li ref={containerRef}>
      <button
        onClick={toggleDropdown}
        
        className="flex items-center sidebar-item px-8 text-white justify-between w-full p-3 focus:outline-none"
      >
        <span>Policy</span>
        <svg
          className={`w-4 h-4 ml-2 transition-transform ${
            isOpen ? 'rotate-180' : 'rotate-0'
          }`}
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      {isOpen && (
        <ul className="pl-6 mt-1" ref={dropdownRef}>
            {policyItems.map((item) => {
                return (
                    <li>
                        <Link
                        to={item.path}
                        className="block py-2 pl-2 pr-6 text-sm text-white hover:bg-primary-red hover:text-white"
                        >
                        {item.title}
                        </Link>
                    </li>
                )
            })}
          
          
        </ul>
      )}
    </li>
  );
}

export default PolicyNavsMobile;
