import React from 'react';

import ReactDOM from 'react-dom';
import {CSSTransition} from 'react-transition-group';
import Backdrop from '../../../../../UI_Elements/Backdrop/Backdrop';

function ImagesModalOverlay(props) {


  let content = (
    <div className="p-8 md:p-18 md:py-20 modal">
        <img src={props.img} alt="display content" className='w-full h-full object-cover'/>
    </div>
  );

  return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
}

const ImagesModal = props => {
    return (
        <React.Fragment>
            {props.show && <Backdrop onClick={props.onCancel}/>}
            <CSSTransition
                in={props.show}
                mountOnEnter
                unmountOnExit
                timeout={200}
                classNames="modal">
                <ImagesModalOverlay {...props}/>
            </CSSTransition>
        </React.Fragment>
    );
};

export default ImagesModal