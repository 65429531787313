import React from 'react'
import TitleHOC from './TitleHOC'
import ItemLi from './ItemLi'

function Fire() {
  return (
    <div>
        <div className="mb-8">
            <TitleHOC 
            title="Fire / Extraneous Perils Insurance" 
            image='https://res.cloudinary.com/devgodfrey/image/upload/v1688508728/fire-bg.png'/>
        </div>

        <div data-aos="fade-up" 
        className={`relative px-8 md:px-16 xl:px-32 py-3 md:py-8 xl:py-0 
        overflow-hidden md:mt-3 xl:mt-0`}>
            <h1 className="text-bg-black text-3xl font-semibold">Fire / Extraneous Perils Insurance</h1>
            <p className="text-bg-black my-6 mb-8 font-light text-lg">
                This form of insurance provides compensation for losses following the incidence of fire, explosion, 
                and lightening. The policy can also be extended to such extraneous perils as: bush fire, 
                impact with vehicle, animal, aerial object, aircraft, storm, tornado, tempest, windstorm, 
                burst pipe, overflowing of water tank, flood, earthquake, subsidence, malicious damage, riot, 
                strike and civil commotion etc.
            </p>

            <p className="text-bg-black my-6 mb-8 font-light text-lg">
            Fire within the purview of insurance is the actual ignition or conflagration of what ought not to 
            be on fire and such fire must be fortuitous /accidental in nature. In practice, the following 
            losses which are connected with fire incidence are covered by the fire insurance policy;
            </p>


            <div className="mb-4">
                <ItemLi text="Damage by fire brigade in the cause of extinguishing the fire."/>
                <ItemLi text="Property damaged by water or extinguishing agents."/>
                <ItemLi text="Loss or damage to property removed from a burning building, see Stanley V. Western. Ins. Co (1868)."/>
            </div>
        </div>

    </div>
  )
}

export default Fire