import React, { useCallback, useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import {CSSTransition} from 'react-transition-group';


//custom components
import Backdrop from '../../../UI_Elements/Backdrop/Backdrop';
import FormOne from './PolicyMotorForm/FormOne';
import FormTwo from './PolicyMotorForm/FormTwo';

//CSS packages
// import * as Icons from 'react-icons/md' 
import * as IconT from "react-icons/io";
import FormThree from './PolicyMotorForm/FormThree';
import { useHttpClient } from '../hooks/dashboard-http-hook';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/auth-context';
import Loader from '../../../UI_Elements/loader/Loader';



function PolicyMotorOverlay(props) {

    const [userData,setUserData] = useState(null)
    const [vehicleData,setVehicleData] = useState(null)
    const [insuranceData,setInsuranceData] = useState(null)

    const history = useNavigate()

    const [tableData, setTableData] = useState({
        currentPage: 1,
        lastPage:2
    })

    const auth = useContext(AuthContext)

    const { error, sendRequest, isLoading} = useHttpClient();


    const pageChangeHandler = useCallback((item) =>{

        if(item === "forward" && tableData.currentPage <= tableData.lastPage-1){
            setTableData({
                ...tableData,
                currentPage:tableData.currentPage+1
            })
        }

        if(item === "back" && tableData.currentPage > 1){
            setTableData({
                ...tableData,
                currentPage:tableData.currentPage-1
            })
        }
    },[tableData])


    const userHandler = useCallback((data)=>{
        setUserData(data)
    },[])

    const vehicleHandler = useCallback((data)=>{
        setVehicleData(data)
    },[])

    const insuranceHandler = useCallback((data)=>{
        setInsuranceData(data)
    },[])


    const submitHandler = async(event) => {
        event.preventDefault();


        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/carinsurance/create/stepone`, 
            "POST", 
            JSON.stringify({
                
                "name_of_proposer": `${userData.firstname} ${userData.lastname}`,
                "policy_id": props.policy.id,
                "address_of_proposer": userData.address,
                "age_of_proposer": userData.birthDate.toString(),
                "lga": userData.lga,
                "state":userData.state,
                "proposer_occupation": userData.occupation,
                "drivers_license": userData.driverLicense.toString(),
                "vehicle_garage": "vehicleData.garage",
                "vehicle_make": vehicleData.vehicleMake,
                "vehicle_model": vehicleData.vehicleModel,
                "vehicle_type": vehicleData.vehicleTypes,
                "vehicle_color": vehicleData.vehicleColor,
                "vehicle_registration_number": vehicleData.registrationNumber,
                "vehicle_chassis_number": vehicleData.chasisNumber,
                "vehicle_engine_number": vehicleData.engineNumber,
                "value_of_vehicle": vehicleData.valueOfAsset,
                "photo_of_vehicle": vehicleData.uploadImages.toString(),
                "use_of_vehicle": vehicleData.useOfVehicle,
                "type_of_cover": vehicleData.typeOfCover,
                "third_party": "insuranceData.insuranceDate",
                "insurance_history": "insuranceData.insuranceHistory",
                "claims_history": "insuranceData.claimsHistory"
            }), 
            {'Content-Type': 'application/json',Authorization: 'Bearer '+auth.token});
            
            history(`${responseData.insurance.id}`)
            

        } catch (err) {
            
            console.log(error)
        }

    }


  let pageOne = (
    <div className="p-8 md:p-18 md:py-10 modal flex flex-col justify-center align-items">
        {
            tableData.currentPage === 1 && <h1 className='text-primary-red'>User Information On Motor Policy</h1>
        }

        {
            tableData.currentPage === 2 && <h1 className='text-primary-red'>Vehicle Information On Motor Policy</h1>
        }

        {
            tableData.currentPage === 3 && <h1 className='text-primary-red'>Insurance Information On Motor Policy</h1>
        }

        <hr className='mb-4 lg:mb-8'/>

        

        {
            tableData.currentPage === 1 && <FormOne 
            input={userHandler} 
            {...userData}
            />
        }

        {
            tableData.currentPage === 2 && <FormTwo input={vehicleHandler} {...vehicleData}/>
        }

        {
            tableData.currentPage === 3 && <FormThree input={insuranceHandler} {...insuranceData}/>
        }
        
        {
            tableData.currentPage === 2 && 
            <div className="flex justify-center items-center w-full mx-auto md:col-span-2 my-6  ">
                <button
                className={`form__submit py-3 px-8 md:px-16 lg:px-24 text-white`} 
                onClick={submitHandler} >Submit & See Insurers</button>
                {isLoading && <Loader/>}
            </div>
        }

        <div className='mx-auto mb-2 mt-8'>

        
        {/* <button className={`${tableData.currentPage > 1 ? 'dash_cell_bg' : 'bg-white dash_disable_border'} h-8 w-8 dash_border_radius inline-flex justify-center items-center`} onClick={() => pageChangeHandler("back")} >
        <Icons.MdArrowBackIos className={`${tableData.currentPage > 1? 'dash_cell_color' : 'dash_color_disabled'} font-extrabold`} onClick={() => pageChangeHandler("back")}/>{' '}
        </button>
        

        <span>
            <span className='mx-2'>
                {tableData["currentPage"]}
                {' '} of {tableData.lastPage}
            </span>{' '}
        </span>

        <button className={`${tableData.currentPage < tableData.lastPage ? 'dash_cell_bg' : 'bg-white dash_disable_border'} h-8 w-8 dash_border_radius inline-flex justify-center items-center`} onClick={() => pageChangeHandler("forward")} >
            <Icons.MdArrowForwardIos className={`${tableData.currentPage < tableData.lastPage ? 'dash_cell_color' : 'dash_color_disabled'} font-extrabold`}/>
        </button> */}

        {tableData.currentPage !== 2 && (
            <button 
            className={`${userData === null ? 'form__submit__disabled' : Object.values(userData).every(x => (x !== '')) ? 'form__submit' : 'form__submit__disabled'} py-2 px-4 dash_border_radius inline-flex justify-center items-center text-white`} 
            onClick={() => pageChangeHandler("forward")} 
            disabled={userData === null ? true : Object.values(userData).every(x => (x !== ''))? false :true}>
                Next    
            </button>
        ) }
        
        </div>

        <div className='absolute top-4 right-4'>
          <button onClick={props.onCancel}>
              <IconT.IoIosClose
                  className='text-base text-red-700 bg-red-300 rounded pointer'/>
          </button>
      </div>
    </div>
  );

  return ReactDOM.createPortal(pageOne, document.getElementById('modal-hook'));
}

const PolicyMotor = props => {
    return (
        <React.Fragment>
            {props.show && <Backdrop onClick={props.onCancel}/>}
            <CSSTransition
                in={props.show}
                mountOnEnter
                unmountOnExit
                timeout={200}
                classNames="modal">
                <PolicyMotorOverlay {...props}/>
            </CSSTransition>
        </React.Fragment>
    );
};

export default PolicyMotor