import React from 'react'

function TitleHOC(props) {
    const {title,image} = props
  return (
    <div className="title-bg h-65vh flex justify-center items-center  pt-3">
        <div 
        className={`
        w-4/5 h-64 md:h-80 xl:h-96 md:mt-16 xl:mt-0 text-white font-light flex 
        justify-end items-end text-right 
        `} 
        style={{
            background:`url(${image})`,
            backgroundSize:"cover",
            backgroundRepeat:"no-repeat",
            backgroundPosition:"bottom",
        }}>
            <p className="p-6 text-2xl md:text-3xl lg:text-5xl w-full md:w-3/5 xl:w-2/5">{title}</p>
        </div>
    </div>
  )
}

export default TitleHOC