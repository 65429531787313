import React from 'react'

import './LoadingSpinner.css';

function Loader(props) {
    return (
        <div className={`${props.asOverlay && 'loading-spinner__overlay'} flex justify-center items-center `}>
            <div className="lds-dual-ring"></div>
        </div>
    )
}

export default Loader
