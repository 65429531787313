import React from 'react'

function TestimoniesItem(props) {
    return (
        <div className='p-4 md;p-8 lg:p-12 m-4 bg-red-500 rounded-2xl bg-opacity-5'>
            <p className='md:text-xl text-sm'>{props.text}</p>

            {props.src !== null
                ? <div className='flex justify-start items-center mt-8 lg:mt-16'>
                        <img src={props.src} alt={props.alt} className='w-8 h-8 rounded-full object-cover mr-4'/>
                        <div>
                            <p className='text-base'>{props.name}</p>
                            <p className='text-sm'>{props.date}</p>
                        </div>
                    </div>
                : <div className='flex justify-start items-center mt-8 lg:mt-16'>
                    <div className={`rounded-full w-8 h-8 ${props.bg} flex justify-center item-center mr-4 `}>
                        <p className={`text-base uppercase my-auto ${props.color}`}>{props
                                .name
                                .split('')[0]}</p>
                    </div>
                    <div >
                        <p className='text-base'>{props.name}</p>
                        <p className='text-sm'>{props.date}</p>
                    </div>
                </div>}
        </div>
    )
}

export default TestimoniesItem
