export const images = {
    logo: [require('./leadfort-logo.png'), "Leadfort Insurance Brokers"],
    coverBackground: [require('./leadfort-room.svg'), "Leadfort Cover"],
    fireInsurance: [require('./fire-insurance.png'), "Fire insurance"],
    machineryInsurance: [require('./machinery-insurance.png'), "Machinery Insurance"],
    personalInsurance: [require('./personal-insurance.png'), "Personal Insurance"],
    burglaryInsurance: [require('./burglary-insurance.png'), "Burglary Insurance"],
    leadfortInsurance: [require('./leadfort-insurance.png'), "Leadfort Insurance"],
    motorInsurance: [require('./car-insurance.png'), "Motor Insurance"],
    OvalImage: [require('./Oval-img.png'), "King Paul Image"],
    FormImage: [require('./form-image.png'), "Form Happy"],
    CircleImage: [require('./circle.png'), "Circle"],
    Notification: [require('./Notification.png'), "Notification"],
    Message: [require('./Message.png'), "Message"],
    AdobeDownload: [require('./Adobe-download.png'), "File Download Button"],
    ClaimsFinish: [require('./claims-finish.png'), "Claims Submitted"],
    DotDesign: [require('./dot-design.png'), "dots design"],
    FrontCircle: [require('./Front-Circle.png'), "Front Circle"],
    glassesIcon: [require('./glasses-icon.png'), "Glasses"],
    chessIcon: [require('./chess-piece.png'), "Chess piece"],
    starGreen: [require('./star-green.png'), "star green"],
    starBlue: [require('./star-blue.png'), "star blue"],
    starRed: [require('./star-red.png'), "star red"],
    aboutImage: [require('./about-image.png'), "about"],
    dot: [require('./dot.png'), "dot"],
}