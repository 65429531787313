import React, {useState, useEffect} from 'react'
// import {Link} from 'react-router-dom' import { NavLink } from
// 'react-router-dom'

import Button from '../../../UI_Elements/Button/Button'

import {navItems} from "./NavItems.js";
import {images} from '../../../assests/index'

import * as Icons from "react-icons/fa";
import "./Navbar.css";
import PolicyNavs from './PolicyNavs';
import PolicyNavsMobile from './PolicyNavsMobile';


function Landing() {
    const [mobile,
        setMobile] = useState(false);
    const [sidebar,
        setSidebar] = useState(false);

    

    useEffect(() => {
        if (window.innerWidth < 800) {
            setMobile(true);
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 800) {
                setMobile(true);
            } else {
                setMobile(false);
                setSidebar(false);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
        <div className='flex justify-between items-center '>
            <img
                src={images.logo[0]}
                alt={images.logo[1]}
                title={images.logo[1]}
                caption={images.logo[1]}
                className='object-contain w-32'/> 
                
                {!mobile && (
                <ul className='flex'>
                    {navItems.map((item) => {
                        return (
                            <li key={item.id} className={item.nName}>
                                <Button to={item.path} ButtonClassName={`${item.nName} text-base p-6`}>{item.title}</Button>
                            </li>
                        );
                    })
                    }
                    <li>
                        <PolicyNavs/>
                    </li>
                </ul>
            )}

            {!mobile && (
                <div className='flex justify-between'>
                    <Button to='/login' padding marginright>Sign In</Button>
                    <Button to='/signup' colored>Get Started</Button>
                </div>
            )}

        {mobile && (
          <div className="sidebar-toggle">
            {sidebar ? (
              <Icons.FaTimes
                className="sidebar-toggle-logo"
                onClick={() => setSidebar(!sidebar)}
              />
            ) : (
              <Icons.FaBars
                className="sidebar-toggle-logo"
                onClick={() => setSidebar(!sidebar)}
              />
            )}
          </div>
        )}
        </div>
        
        <div className={sidebar ? "sidebar active" : "sidebar"} >
        <div className='span__pseudo' onClick={() => setSidebar(!sidebar)}></div>
        <div className='w-full bg-white'>
        <img
                src={images.logo[0]}
                alt={images.logo[1]}
                title={images.logo[1]}
                caption={images.logo[1]}
                className='object-contain w-32 p-4 pt-4'/> 
        </div>
        
        <ul className="sidebar-items">
        {navItems.map((item) => {
                        return (
                            <li key={item.id} className={item.sName}>
                                <Button to={item.path} 
                                ButtonClassName={`text-base text-white`}
                                onClick={() => setSidebar(!sidebar)}>{item.title}</Button>
                            </li>
                            
                        );
                    })
        }

            <li>
                <PolicyNavsMobile/>
            </li>
        </ul>
        <div className='flex flex-col items-center mt-24'>
                    <Button to='/login' padding marginright textWhite>Sign In</Button>
                    <Button to='/signup' colored>Get Started</Button>
        </div>
      </div>
        </>
    )
}

export default Landing
