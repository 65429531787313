import React, {useReducer, useEffect, useState} from 'react';

import {validate} from '../../util/validators';

import * as Icons from "react-icons/md"

import './input.css'

const inputReducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE':
            return {
                ...state,
                value: action.val,
                isValid: validate(action.val, action.validators)
            };
        case 'TOUCH':
            {
                return {
                    ...state,
                    isTouched: true
                }
            }
        default:
            return state;
    }
};

function Input(props) {

    const [passwordValue,
        setPasswordValue] = useState(false)

    const [inputState,
        dispatch] = useReducer(inputReducer, {
        value: props.initialValue || '',
        isTouched: false,
        isValid: props.initialValid || false
    });

    const {id, onInput} = props;
    const {value, isValid} = inputState;

    useEffect(() => {
        onInput(id, value, isValid)
    }, [id, value, isValid, onInput]);

    const changeHandler = event => {
        dispatch({type: 'CHANGE', val: event.target.value, validators: props.validators});
    };

    const touchHandler = () => {
        dispatch({type: 'TOUCH'});
    };


    return (
        <div className='flex flex-col w-full'>
            <label className='text-sm mb-2' htmlFor={id}>{props.label}</label>

            {props.type === 'password'
                ? (
                    <div
                        className='border-2  rounded-sm border-slate-300 bg-white relative w-full'>
                        
                        <input
                            type={!passwordValue
                            ? props.type
                            : "text"}
                            id={id}
                            placeholder={props.placeholder}
                            onChange={changeHandler}
                            onBlur={touchHandler}
                            value={inputState.value}
                            style= {{border:"none !important", borderWidth:"0px"}}
                            className='bg-white p-2 w-full input_button' autoComplete='on'/> 
                            
                            {passwordValue
                            ? <Icons.MdOutlineVisibility
                                    className="text-primary-black absolute top-1/3 right-2 cursor-pointer"
                                    onClick={(e) => setPasswordValue(!passwordValue)}/>
                            : <Icons.MdOutlineVisibilityOff
                                className="text-primary-black absolute top-1/3 right-2 cursor-pointer"
                                onClick={(e) => setPasswordValue(!passwordValue)}/>}
                    </div>
                )
                : (<input
                    type={props.type}
                    id={id}
                    placeholder={props.placeholder}
                    onChange={changeHandler}
                    onBlur={touchHandler}
                    value={inputState.value}
                    className='border-2 p-2 rounded-sm border-slate-300 bg-white input_button '/>)}
            {!inputState.isValid && inputState.isTouched && <p className='text-red-600 text-xs mt-1'>{props.errorText}</p>}
        </div>
    )
}

export default Input
