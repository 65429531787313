import React, {useContext, useState} from 'react'

import Button from '../UI_Elements/Button/Button'
import Input from '../UI_Elements/Input/Input'
import Loader from '../UI_Elements/loader/Loader';
import Google from './components/Google'

import {images} from '../assests/index'

import {useForm} from '../hooks/form-hook'
import {useHttpClient} from '../hooks/http-hook';
import {AuthContext} from '../context/auth-context';

import './auth.css'


import {VALIDATOR_EMAIL, VALIDATOR_MINLENGTH} from '../util/validators'
import { useNavigate } from 'react-router-dom';
import ResetModal from './ForgotModal';
import { signInWithGoogle } from './Firebase/FirebaseConfig';

function Login() {
    const history = useNavigate()

    const auth = useContext(AuthContext);

    const [forgot, setforgot] = useState(false)

    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
        inputHandler] = useForm({

        email: {
            value: '',
            isValid: false
        },
        password: {
            value: '',
            isValid: false
        }
    }, false);

    const authSubmitHandler = async event => {
        event.preventDefault();
        

        try {

            const data = JSON.stringify({
                email: formState.inputs.email.value,
                password: formState.inputs.password.value
            })

            const responseData = await
            sendRequest(`${process.env.REACT_APP_API_CALL}/customers/login`, 'POST', data, {'Content-Type': 'application/json'});
            auth.login(responseData.customer.authId, responseData.token);
            history("/dashboard")

        } catch (err) {
            
            console.log(error)
        }
    };


    const googleSubmitHandler = async event => {
        event.preventDefault();
        

        await signInWithGoogle().then(
            (response)=>{
                const fetch = async() => {
                    try {

                        const data = JSON.stringify({
                            
                            email: response._tokenResponse.email,
                            google_id: response._tokenResponse.localId
                        })
                        const responseData =
                        await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/login/google`, 'POST', data,{'Content-Type': 'application/json'});
                        auth.login(responseData.customer.authId, responseData.token);
                        history("/dashboard")
            
                    } catch (err) {
                        console.log(err)
                        
                    }
                }

                fetch()
            }
        ).catch((error) => {
            console.log(error)
        })

        
    };


    const resetPassword =() =>{
        
        return setforgot(!forgot)
    }

    const onCancel = () =>{
        setforgot(null)
    }

    return (
        <>
            <ResetModal show={forgot} onCancel={onCancel}/>
        
        <div
             className='grid grid-cols-1 lg:grid-cols-3 relative pb-16 md:pb-16 lg:pb-32'>
            <Button to='/'>
                <img
                    src={images.FormImage[0]}
                    alt={images.FormImage[1]}
                    className='hidden lg:block'/>
            </Button>
            <div data-aos="fade-left" className='mt-8 col-span-2 px-4 md:px-8 xl:px-32'>
                <div className='flex flex-col md:flex-row justify-between items-center'>
                <Button to='/'>
                        <img
                            src={images.logo[0]}
                            alt={images.logo[1]}
                            className='w-32 object-contain mb-8 md:mb-0'/>
                    </Button>
                    <div className='flex flex-row justify-center items-center md:pr-8'>
                        <p className='text-sm opacity-50 mr-1'>New to Leadfort?</p>
                        <Button to='/signup' transparent nopadding>Get Started</Button>
                    </div>
                </div>

                <div className='mt-8 md:mt-16 mb-10 '>
                    <h1 className='text__heading text-center md:text-left'>Login</h1>
                    <p className='text-base opacity-50 mt-2 text-center md:text-left'>Login to your account and enjoy your ride</p>
                </div>

                {!forgot && 
                    <form className='md:w-4/5' onSubmit={authSubmitHandler}>
                    <div className='grid grid-cols-1 gap-8 md:w-4/5'>

                        <Input
                            type="email"
                            placeholder='sampleemail@gmail.com'
                            label="Email Address"
                            id="email"
                            validators={[VALIDATOR_EMAIL()]}
                            errorText="Please enter a valid email"
                            onInput={inputHandler}/>

                        <Input
                            type="password"
                            placeholder=''
                            label="Password"
                            id="password"
                            validators={[VALIDATOR_MINLENGTH(8)]}
                            errorText="Please enter more 8 characters"
                            onInput={inputHandler} 
                            />

                    </div>

                    <div className='my-8 flex flex-row justify-between items-center md:w-4/5'>
                        <div className='flex items-center justify-center'>
                            <input type='checkbox' className='type__checkbox mr-2' id='remember'/>
                            <label className='flex justify-start items-center' htmlFor='remember'>
                                <p className='text-sm opacity-60 mr-1'>Remember me</p>
                            </label>
                        </div>
                        <a href='#forgotpassword' onClick={resetPassword} className='primary__color'>Forgot Password</a>
                    </div>

                    <div className='flex justify-start items-center'>
                        <input
                            type='submit'
                            className={`form__submit button__padding text-white mr-2  `}
                            value="Login into account" 
                            /> {isLoading && <Loader/>}
                    </div>
                    <div className='mt-8'></div>
                    <Google text='Login with Google' onClick={googleSubmitHandler}/>

                </form>
                }
            </div>

            <img
                src={images.CircleImage[0]}
                alt={images.CircleImage[1]}
                className='absolute -bottom-10 right-0 -z-10'/>
        </div>
        </>
    )
}

export default Login
