import {images} from '../../../assests/index'

export const testimoniesItems = [
    {
        name:'Aiyenigba Olalekan',
        id:1,
        date:'April 13, 2021',
        src:null,
        alt:null,
        bg:'bg-red-400',
        color:'text-white',
        text:'Fast and convenient for instant loans. It has seen my through difficulties when I need urgent cash. Excellent service.'
    },
    {
        name:'King Paul Live',
        id:2,
        date:'April 15, 2021',
        src:images.OvalImage[0],
        alt:images.OvalImage[1],
        text:'Lend me is a very accurate and wonderful loan app, and provides an easy-to-repay method that is second to none.'
    },
    {
        name:'Emmanuel Tumba',
        id:3,
        date:'April 13, 2021',
        src:null,
        alt:null,
        bg:'bg-emerald-400',
        color:'text-black',
        text:'Excellent, LendMe is the most flexible, fair, fast and considerate online lending company in the whole of Africa.'
    },
    
]
