// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAtyT2zxAFPErw-AHhvpf8XNyCykIDYNAs",
  authDomain: "leadfort-bb36d.firebaseapp.com",
  projectId: "leadfort-bb36d",
  storageBucket: "leadfort-bb36d.appspot.com",
  messagingSenderId: "9066666680",
  appId: "1:9066666680:web:e625ed93ce6a280e28f24d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const authFirebase = getAuth(app);

const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => signInWithPopup(authFirebase, provider)
