export const vehicleMake = [
    "Audi",
    "Chevrolet",
    "Cadillac",
    "Acura",
    "BMW",
    "Chrysler",
    "Ford",
    "Buick",
    "INFINITI",
    "GMC",
    "Honda",
    "Hyundai",
    "Jeep",
    "Genesis",
    "Dodge",
    "Jaguar",
    "Kia",
    "Land Rover",
    "Lexus",
    "Mercedes-Be",
    "Mitsubishi",
    "Lincoln",
    "MAZDA",
    "Nissan",
    "MINI",
    "Porsche",
    "Ram",
    "Subaru",
    "Toyota",
    "Volkswagen",
    "Volvo",
    "Alfa Romeo",
    "FIAT",
    "Freightline",
    "Maserati",
    "Tesla",
    "Aston Marti",
    "Bentley",
    "Ferrari",
    "Lamborghini",
    "Lotus",
    "McLaren",
    "Rolls-Royce",
    "smart",
    "Scion",
    "SRT",
    "Suzuki",
    "Fisker",
    "Maybach",
    "Mercury",
    "Saab",
    "HUMMER",
    "Pontiac",
    "Saturn",
    "Isuzu",
    "Panoz",
    "Oldsmobile",
    "Daewoo",
    "Plymouth",
    "Eagle",
    "Geo",
    "Daihatsu"
    ]

    export const vehicleTypes = [
        "Sedan",
        "SUV",
        "Coupe, Conv",
        "Pickup",
        "Van/Minivan",
        "Convertible",
        "Hatchback, ",
        "Hatchback",
        "Coupe",
        "Sedan, Hatc",
        "Wagon",
        "Coupe, Wago",
        "Wagon, Seda",
        "Sedan, Coup",
        "SUV1992",
        "Coupe, Seda",
        "Sedan, Wago",
        "Sedan, Conv",
        "SUV2020",
        "Coupe, Hatc",
        "Wagon, Conv",
        "SUV, Pickup",
        "Wagon, SUV",
        "SUV, Wagon",
        ]

        export const vehicleYears = [
            2022,
            2021,
            2020,
            2019,
            2018,
            2017,
            2016,
            2015,
            2014,
            2013,
            2012,
            2011,
            2010,
            2009,
            2008,
            2007,
            2006,
            2005,
            2004,
            2003,
            2002,
            2001,
            2000,
            1999,
            1998,
            1997,
            1996,
            1995,
            1994,
            1993,
            1992
            ]