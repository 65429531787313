import React, {useState, useRef, useEffect} from 'react';


import * as Icons from "react-icons/ti";

import * as IconT from 'react-icons/bi'

import {createPopper} from "@popperjs/core";


function PolicyList(props) {
    const [policyValue,
        setPolicyValue] = useState({
            value:"",
            id:"",
            attach_policies:null
        })

      const {onInput } = props;

  useEffect(() => {
    
    onInput(policyValue)
  }, [policyValue, onInput]);

    const [query, setQuery] = useState("");

    const [dropdownPopoverShow,
        setDropdownPopoverShow] = useState(false);
    const btnDropdownRef = useRef();
    const popoverDropdownRef = useRef();

    const openDropdownPopover = () => {
        createPopper(btnDropdownRef.current, popoverDropdownRef.current, {placement: "bottom-start"});
        setDropdownPopoverShow(true);
    };

    const closeDropdownPopover = () => {
        setDropdownPopoverShow(false);
    };

    const sortingHandler = (a, b) => {
        if(a.firstname < b.firstname) { return -1; }
        if(a.firstname > b.firstname) { return 1; }
        return 0;
    }

    

    const dataFilter = () => {
        
        if(query === "" || query=== null) {
            return props.options.sort(sortingHandler);
        }

        return props.options.sort(sortingHandler).filter((option) => {
            
            const optionName = option.policy_name.toLowerCase();
            return optionName.includes(query.value)
        })
    }


    return (
        
        <div className={`flex flex-col ${props.classNameContainer}`}>
            <label className='text-sm mb-2' htmlFor='Policy'>{props.label}</label>
            <button className="relative p-2 px-1 border-2 border-slate-300 "
            ref={btnDropdownRef}
            onClick={(e) => {
                e.preventDefault();
                dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
              }}>

            <input
                        type="text"
                        id={props.id}
                        className='outline-none border-0 w-full text-left text-sm lg:text-base '
                        value={policyValue.value} 
                        placeholder={props.placeholder}
                        readOnly
                        />

                <Icons.TiArrowSortedDown
                    className='ml-1 text-base rounded-md text-primary-black absolute top-1/3 right-2'/>

            </button>

            <div
                ref={popoverDropdownRef}
                className={(dropdownPopoverShow
                ? "block "
                : "hidden ") + "bg-white text-base z-50 float-left py-2 list-none text-left max-h-48 rounded overflow-y-scroll shadow-md mi" +
                    "n-w-48"}>

            <div className='flex items-center rounded-full shadow-sm m-2 p-2 border-slate-300 border-2'>
                <input type="text" className='text-sm outline-none border-0' placeholder='search...' id='search_select' onInput={(e) => {
                e.preventDefault();
                setPolicyValue({
                    value:e.target.value
                });
                setQuery({
                    value:e.target.value
                });
            }} />
                <label htmlFor='search_select'>
                    <IconT.BiSearch className='text-slate-500'/>
                </label>
            </div>

            {
                dataFilter().map((post, index) => {
                    return (
                        <button 
                        key={index}
                        className={
                            "text-sm py-2 px-2 pl-4 text-left font-normal block w-full whitespace-nowrap bg-transparent text-primary-black hover:bg-primary-red hover:text-white"
                        }
                        onClick={(e) => {
                            e.preventDefault();
                            setPolicyValue({
                                value:post.policy_name,
                                id:post.policy_id,
                                attach_policies:post.attachpolicy
                            });
                            closeDropdownPopover()
                        }}
                        >
                            {post.policy_name}
                        </button>  
                    )
                })
            }
             

            </div>
        </div>
    )
}


export default PolicyList;
