/*eslint-disable*/
import React from "react";
import { Link, useLocation }  from "react-router-dom";

import {images} from '../../../assests/index';

import * as Icons from "react-icons/gi";
import * as IconT from "react-icons/md";
import * as IconChart from 'react-icons/hi';
import * as IconFile from 'react-icons/bs';
import * as IconWallet from 'react-icons/io';
import * as IconSetting from 'react-icons/ri'

import UserDropdown from "../../../components/Dropdowns/UserDropdown";
import NotificationDropdown from "../../../components/Dropdowns/NotificationDropdown"

export default function Sidebar(props) {
  const [collapseShow, setCollapseShow] = React.useState("hidden ");

  const location = useLocation();
  
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-md bg-white md:bg-black flex flex-wrap items-center justify-between relative md:w-48 xl:w-64 z-10 ">
        <div className="px-4 md:px-0 md:flex-col md:items-stretch md:min-h-full md:flex-nowrap flex items-center justify-between w-full mx-auto  ">
          {/* Toggler */}
          <button
            className="h-12 w-12 cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-base leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-black m-2 py-3 px-6")}
          >
            <Icons.GiHamburgerMenu/>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-lg font-logo px-6 py-8 bg-white"
            to="/dashboard/insurances"
          >
            <img src={images.logo[0]} alt={images.logo[1]} className=" h-10"/>
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              " md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm font-logo p-4 px-0"
                    to="/dashboard/insurances"
                  >
                    <img src={images.logo[0]} alt={images.logo[1]} className=" h-10"/>
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <IconT.MdClose className="text-white"/>
                  </button>
                </div>
              </div>
            </div>
            
            {/* Divider */}
            {/* <hr className="my-4 md:min-w-full" /> */}
            {/* Heading */}
            
            {/* Navigation */}

            <ul className="px-4 py-2 md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "flex items-center text-xs uppercase py-3 font-bold rounded-md pl-2 " +
                    (location.pathname === "/dashboard/insurances"
                      ? "text-warmGray-100 hover:bg-hover-black"
                      : "text-white opacity-40 hover:bg-hover-black hover:opacity-60")
                  }
                  to="/dashboard/insurances"
                >
                  <IconChart.HiPresentationChartLine
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (location.pathname === "/dashboard/insurances"
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></IconChart.HiPresentationChartLine>{" "}
                  My Insurances
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "flex items-center text-xs uppercase py-3 font-bold rounded-md pl-2 " +
                    (window.location.href.indexOf("dashboard/policies") !== -1
                      ? "text-warmGray-100 hover:bg-hover-black"
                      : "text-white opacity-40 hover:bg-hover-black hover:opacity-60")
                  }
                  to="/dashboard/policies"
                >
                  <IconFile.BsFillFileEarmarkTextFill
                    className={
                      "fas fa-hashtag mr-2 text-sm " +
                      (window.location.href.indexOf("dashboard/policies") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></IconFile.BsFillFileEarmarkTextFill>{" "}
                  Insurance Policies
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "flex items-center text-xs uppercase py-3 font-bold rounded-md pl-2 " +
                    (window.location.href.indexOf("/dashboard/payments") !== -1
                      ? "text-warmGray-100 hover:bg-hover-black"
                      : "text-white opacity-40 hover:bg-hover-black hover:opacity-60")
                  }
                  to="/dashboard/payments"
                  
                >
                  <IconWallet.IoIosWallet
                    className={
                      "fas fa-hashtag mr-2 text-sm " +
                      (window.location.href.indexOf("dashboard/payments") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></IconWallet.IoIosWallet>{" "}
                  Payments
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "flex items-center text-xs uppercase py-3 font-bold pl-2 " +
                    (window.location.href.indexOf("/dashboard/claims") !== -1
                      ? "text-warmGray-100 hover:bg-hover-black"
                      : "text-white opacity-40 hover:bg-hover-black hover:opacity-60")
                  }
                  to="/dashboard/claims"
                  
                >
                  <IconFile.BsFillFileEarmarkTextFill
                    className={
                      "fas fa-hashtag mr-2 text-sm " +
                      (window.location.href.indexOf("dashboard/claims") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></IconFile.BsFillFileEarmarkTextFill>{" "}
                  Claims
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "flex items-ceneter text-xs uppercase py-3 font-bold rounded-md pl-2 " +
                    (window.location.href.indexOf("/dashboard/settings") !== -1
                      ? "text-warmGray-100 hover:bg-hover-black"
                      : "text-white opacity-40 hover:bg-hover-black hover:opacity-60")
                  }
                  to="/dashboard/settings"
                  
                >
                  <IconSetting.RiSettings4Fill
                    className={
                      "fas fa-print mr-2 text-sm " +
                      (window.location.href.indexOf("/dashboard/settings") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></IconSetting.RiSettings4Fill>{" "}
                  Settings
                </Link>
              </li>

              {/* <li className="items-center">
                <Link to='/login'
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  onClick={logout}
                >
                  <i className="fas fa-fingerprint text-blueGray-400 mr-2 text-sm"></i>{" "}
                  Logout
                </Link>
              </li> */}

             </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
