export const navItems = [
  {
    id: 1,
    title: "Home",
    path: "/",
    nName: "nav-item",
    sName: "sidebar-item"
  },
  {
    id: 2,
    title: "About",
    path: "/about",
    nName: "nav-item",
    sName: "sidebar-item"
  },
  // {
  //   id: 3,
  //   title: "Our Policies",
  //   path: "/policies",
  //   nName: "nav-item",
  //   sName: "sidebar-item"
  // },
  // {
  //   id: 4,
  //   title: "Help & FAQ",
  //   path: "#help",
  //   nName: "nav-item",
  //   sName: "sidebar-item"
  // },
];
