export const policyItems = [
    {
      title: "Marine Insurance",
      path: "/marine",
    },
    {
      title: "Oil & Energy Insurance",
      path: "/oil-energy",
    },
    {
        title: "Motor Insurance",
        path: "/motor",
      },
      {
        title: "Fire / Extraneous Perils Insurance",
        path: "/fire-extraneous",
      },
      {
        title: "Erection All Risks Insurance",
        path: "/erection-all-risks",
      }
  ];
  