

export const INSURANCES_COLUMNS = [
  {
    Header: 'Id',
    accessor: 'id',
    
  },
  {
    Header: 'Policy',
    accessor: 'policy',
    
  },
  {
    Header: 'Insurer',
    accessor: 'insurer',
    
  },
  {
    Header: 'Premium',
    accessor: 'premium'
  },
  {
    Header: 'Start Date',
    accessor: 'start_date'
  },
  {
    Header: 'Expiry Date',
    accessor: 'expiry_date'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'Actions',
    accessor: 'actions'
  },
]



export const CLAIMS_COLUMNS = [
  {
    Header: 'Claim ID',
    accessor: 'id',
    disableFilters: true
  },
  {
    Header: 'Circumstance',
    accessor: 'circumstance'
  },
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'Insurance Policy',
    accessor: 'policy'
  },
  {
    Header: 'Insurer',
    accessor: 'insurer'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
]


export const PAYMENTS_COLUMNS = [
  {
    Header: 'Transaction ID',
    accessor: 'id'
  },
  
  {
    Header: 'Date',
    accessor: 'date'
  },

  {
    Header: 'Amount',
    accessor: 'amount'
  },

  {
    Header: 'Policy',
    accessor: 'policy'
  },
  {
    Header: 'Insurer',
    accessor: 'insurer'
  },
  
]