import React, {useCallback, useContext, useEffect, useState} from 'react';

import Swal from 'sweetalert2';

import {useForm} from '../../../../hooks/form-hook';
import {VALIDATOR_REQUIRE} from '../../../../util/validators';
import {useHttpClient} from '../../hooks/dashboard-http-hook';
import {AuthContext} from '../../../../context/auth-context';


import HOC from '../../HOC/HOC';
import Loader from '../../UI_Elements/Loader/Loader';

import Input from '../../UI_Elements/Input/Input';

import ClaimsList from './ClaimsList';
import Select from '../../UI_Elements/Select/Select'
import DropboxUpload from './DropboxUpload';
import DocumentUpload from './DocumentUpload';
import ClaimsModal from './ClaimsModal';

import {circumstances} from '../../util/circumstances' 


function ClaimsDetails(props) {

    const auth = useContext(AuthContext)

    const [show, setShow] = useState(false);

    const [edit, setEdit] = useState(false);

    const [claimId, setClaimId] = useState()

    const [policy,
        setPolicy] = useState({});

    const [circumstance,
            setCircumstance] = useState({});

    const [loadedPolicies,
        setLoadedPolicies] = useState();

    const [uploadImages, setUploadImages] = useState([])

    const [uploadDocuments, setUploadDocuments] = useState([])

    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
        inputHandler] = useForm({
        description: {
            value: '',
            isValid: false
        }

    }, false);

    const {onEdit} = props

    useEffect(() =>{
        onEdit(!edit)
    },[onEdit, edit])

    useEffect(() => {
        const fetchPolicies = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/insurance/listforcustomer`, "GET", null, {Authorization: 'Bearer '+auth.token});
                setLoadedPolicies(responseData.insurance);

            } catch (err) {
                console.log(error)
            }
        };
        fetchPolicies();
    }, [sendRequest, error, auth]);

    const policyHandler = useCallback((value) => {
        setPolicy(value)

    }, [])

    const circumstanceHandler = useCallback((value) => {

        setCircumstance(value)

    }, [])

    const imagesHandler = useCallback( (files) => {
        
        setUploadImages(files)
        

    }, [])

    const documentsHandler = useCallback((value) => {
        setUploadDocuments(value)
        
        
    }, [])


    


    const submitHandler = async event => {
        event.preventDefault();

        if(!policy.insurance_id || !circumstance || !formState.inputs.description.value || !uploadImages || !uploadDocuments){
            
            let swalAlert = await Swal.fire('Error', "Please kindly complete the form", 'error');
                if (swalAlert.isConfirmed) {
                    return
                }
        }
        
        try {

            const formData = new FormData();
            formData.append('insurance_id', policy.insurance_id);
            formData.append('circumstance', circumstance);
            formData.append('description', formState.inputs.description.value);
            formData.append('images', JSON.stringify(uploadImages));
            formData.append('documents', JSON.stringify(uploadDocuments));

            


            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/claim/create`, 
            "POST",formData, 
            {Authorization: 'Bearer '+auth.token});

            setShow(!show)

            setClaimId(responseData.claim.claim_id)
            
            

        } catch (err) {
            console.log(error)
        }

    }

    const onCancel = () => {
        setShow(!show)
    }

    const onClaims = () => {
        setEdit(!edit)
    }

    return (
        <HOC>
            <ClaimsModal show={show} onCancel={onCancel} claims_id={claimId} policy={policy["policy_name"]} company={policy.company_name} onClaims={onClaims}/>
                <div className='md:w-4/5 xl:w-3/5 bg-white mt-8 lg:mt-12 xl:mt-16 shadow rounded-lg'>
                <div className='bg-slate-200 h-24 rounded-t-lg'></div>
                {isLoading && <Loader text="Generating / Updating Information for you" />}

                {!isLoading && loadedPolicies && (
                    <form
                        className='grid grid-cols-1 md:grid-cols-2 px-2 md:px-12 py-8 gap-8'
                        onSubmit={submitHandler}>
                        <ClaimsList
                            options={loadedPolicies}
                            label="Select Insurance Policy"
                            id="policy"
                            placeholder='Search'
                            onInput={policyHandler}
                            validators={[VALIDATOR_REQUIRE()]}/>

                        
                        <Select
                            options={circumstances}
                            label="Select Circumstances"
                            id="circumstances"
                            search
                            placeholder=''
                            onInput={circumstanceHandler}
                            validators={[VALIDATOR_REQUIRE()]}/>
                        

                        <Input
                            classNameDiv="md:col-span-2"
                            row='6'
                            label='Description'
                            placeholder='Description'
                            id="description"
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}/>

                        
                        <DropboxUpload colSpan title="Upload Images" accept="image/*" browse="(jpg, png, jpeg)" onFiles={imagesHandler}/>

                        <DocumentUpload colSpan title="Upload Documents" accept=".doc, .docx, .ppt, .pptx, .txt, .pdf" browse="(doc, pdf)" onFiles={documentsHandler}/>
 
                        <div className="flex justify-content items-center w-full mx-auto md:col-span-2">
                            <input
                                type="submit"
                                className={`form__submit py-3 px-8 md:px-16 lg:px-24 text-white mx-auto `} 
                                value="Submit Claims"/>
                        </div>
                    </form>
                )}

            </div>


            
            
        </HOC>
    )
}

export default ClaimsDetails
