import React from 'react'
import Button from '../../UI_Elements/Button/Button'

import * as Icons from "react-icons/fc";

function Google(props) {
    return (
        <Button onClick={props.onClick}>

            <div className='flex justify-start items-center'>
                <Icons.FcGoogle className='text-base'/>
                <p className='ml-2 text-base opacity-80'>{props.text}</p>
            </div>
        </Button>
    )
}

export default Google
