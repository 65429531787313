import { useEffect, useRef, useState } from 'react'
import { Calendar } from 'react-date-range'
import format from 'date-fns/format'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './Calendar.css'

const CalendarComp = (props) => {

  // date state
  const [calendar, setCalendar] = useState('')

  // open close
  const [open, setOpen] = useState(false)

  // get the target element to toggle 
  const refOne = useRef(null)

  const {onInput} = props

  useEffect(() =>{
    onInput(calendar)
  },[onInput, calendar])

  useEffect(() => {
    // set current date on component load
    // if( props.birthDate !== undefined){
    //   setCalendar(format(new Date(props.birthDate), 'MM/dd/yyyy'))
    // }
    // else{
    //   setCalendar(format(new Date(), 'MM/dd/yyyy'))
    // }
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true)
    document.addEventListener("click", hideOnClickOutside, true)
  }, [props.birthDate])

  // hide dropdown on ESC press 
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if( e.key === "Escape" ) {
      setOpen(false)
    }
  }

  // Hide on outside click
  const hideOnClickOutside = (e) => {
    // console.log(refOne.current)
    // console.log(e.target)
    if( refOne.current && !refOne.current.contains(e.target) ) {
      setOpen(false)
    }
  }

  // on date change, store date in state
  const handleSelect = (date) => {
    // console.log(date)
    // console.log(format(date, 'MM/dd/yyyy'))
    setCalendar(format(date, 'MM/dd/yyyy'))
  }

  return (
    <div className="calendarWrap">
      {/* {props.birthDate && console.log(new Date(props.birthDate.toString()))} */}
      <input
        value={ props.birthDate || calendar }
        readOnly
        className="border-2 p-2 rounded-sm border-slate-300 bg-white input_button calendarWrap"
        onClick={ () => setOpen(open => !open) }
      />

      <div ref={refOne}>
        {open && 
          <Calendar
            date={props.birthDate ? new Date(props.birthDate) : new Date()  }
            onChange = { handleSelect }
            className="calendarElement "
          />
        }
      </div>

    </div>
  )
}

export default CalendarComp

// || new Date(calendar)