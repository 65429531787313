import React, { useEffect, useMemo, useState, useCallback } from 'react'

// util components
import { VALIDATOR_REQUIRE } from '../../../../util/validators';
import { useForm } from '../../../../hooks/form-hook';

// custom components
import Input from '../../UI_Elements/Input/Input';
import Select from '../../UI_Elements/Select/Select';
import CalendarComp from '../../UI_Elements/Date/CalendarComp';


function FormThree(props) {

    const [insuranceDate, setInsuranceDate] = useState(null)
    const [typeOfCover, settypeOfCover] = useState('')


    const [formState,
        inputHandler] = useForm({

        insuranceHistory: {
            value: '',
            isValid: false
        },
        claimsHistory: {
            value: '',
            isValid: false
        }
    }, false);

    

    const vehicleData = useMemo(() => {
        const user = {
            insuranceHistory: formState.inputs.insuranceHistory.value,
            claimsHistory: formState.inputs.claimsHistory.value,
            typeOfCover: typeOfCover,
            insuranceDate: insuranceDate
        }
        return user
    }, [formState, typeOfCover, insuranceDate])

    
    const {input} = props

    useEffect(() => {
        input(vehicleData)
    }, [input, vehicleData])


    const typeOfCoverHandler = useCallback((value) => {

        settypeOfCover(value)

    }, [])


  return (

        <div className='grid grid-cols-2 gap-8'>

            <div>
                <p className='text-sm mb-2'>Date of Insurance</p>
                <CalendarComp 
                value={props.insuranceDate || ""}
                onInput={(date) => setInsuranceDate(date)}/> 
            </div>

            <Select 
                options={['Comprehensive', 'Third Party']}
                label="Type of Cover"
                id="typeOfCover"
                initialValue={props.typeOfCover || ""}
                placeholder='Type of Cover'
                onInput={typeOfCoverHandler}/>

            <Input
                classNameDiv={`col-span-2`}
                placeholder='Detailed insurance history of the vehicle'
                label="Insurance History"
                id="insuranceHistory"
                initialValue={props.insuranceHistory || ""}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please this field is required"
                onInput={inputHandler}/>

            <Input
                classNameDiv={`col-span-2`}
                placeholder='Detailed claims history of the vehicle'
                label="Claims History"
                id="claimsHistory"
                initialValue={props.claimsHistory || ""}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please this field is required"
                onInput={inputHandler}/>

            
            

        </div>
  )
}

export default FormThree