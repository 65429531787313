import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'

// util components
import {VALIDATOR_REQUIRE} from '../../../../util/validators';
import {useForm} from '../../../../hooks/form-hook';
import {DataContext} from '../../context/DataContext';

import Input from '../../../../UI_Elements/Input/Input';
import Select from '../../UI_Elements/Select/Select';
import CalendarComp from '../../UI_Elements/Date/CalendarComp';

//npm packages
import NaijaStates from 'naija-state-local-government';
import ImageCollectionItem from '../components/ImageCollectionItem';

function FormOne(props) {

    const data = useContext(DataContext);

    const [uploadDriverLicense,
        setUploadDriverLicense] = useState([])

    const [formState,
        inputHandler] = useForm({

        firstname: {
            value: data.firstname,
            isValid: false
        },
        lastname: {
            value: data.lastname,
            isValid: false
        },

        address: {
            value: '',
            isValid: false
        },
        occupation: {
            value: '',
            isValid: false
        },
        driverLicense: {
            value: '',
            isValid: false
        }
    }, false);

    const [birthDate,
        setBirthDate] = useState(null)

    const [state,
        setState] = useState(null);

    const naijaStates = NaijaStates.states()
    
    const [lga,
        setLga] = useState(null);

    const userData = useMemo(() => {

        const user = {
            firstname: formState.inputs.firstname.value,
            lastname: formState.inputs.lastname.value,
            address: formState.inputs.address.value,
            birthDate: birthDate,
            occupation: formState.inputs.occupation.value,
            driverLicense: uploadDriverLicense === undefined? '' : uploadDriverLicense.length === 0
                ? ''
                : uploadDriverLicense,
            state: state,
            lga: lga
        }
        return user
    }, [formState, birthDate, lga, state, uploadDriverLicense])

    const {input} = props

    useEffect(() => {
        input(userData)
    }, [input, userData])

    const stateHandler = useCallback((value) => {
        if (value === '') {
            return
        }
        setState(value)

    }, [])

    const lgaHandler = useCallback((value) => {

        setLga(value)

    }, [])

    const imagesHandler = useCallback((files) => {

        setUploadDriverLicense(...files)

    }, [])

    return (

        <div className='grid grid-cols-2 gap-8'>
            <Input
                type="text"
                placeholder='First Name'
                label="First Name"
                id="firstname"
                initialValue={data.firstname}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter a valid first name"
                onInput={inputHandler}/>

            <Input
                type="text"
                placeholder='Last Name'
                label="Last Name"
                id="lastname"
                initialValue={data.lastname}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter a valid last name"
                onInput={inputHandler}/>

            <Input
                type="text"
                placeholder='Residential Address'
                label="Residential Address"
                id="address"
                initialValue={props.address || ""}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter a valid residential address"
                onInput={inputHandler}/>

            <Select
                options={naijaStates}
                label="State"
                id="state"
                initialValue={props.state || ""}
                placeholder=''
                onInput={stateHandler}/> 
            {state && (<Select
                options={NaijaStates
                .lgas(state)
                .lgas}
                search
                label="LGA"
                id="LGA"
                value={props.lga || ""}
                placeholder=''
                onInput={lgaHandler}/>)}

            {/* {console.log(props.birthDate)} */}

            <div>
                <p className='text-sm mb-2'>Date of Birth</p>
                <CalendarComp
                    value={props.birthDate || birthDate}
                    onInput={(date) => setBirthDate(date)}/>
            </div>

            <Input
                type="text"
                placeholder='Occupation'
                label="Occupation"
                id="occupation"
                initialValue={props.occupation || ""}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please this field is required"
                onInput={inputHandler}/>

            <div className='col-span-2'>
                <h1 className="pb-2 dash__color w-full dash_border-b border-black mb-4 xl:mb-8">Driver License</h1>
                <ImageCollectionItem
                    className="w-full col-span-2"
                    multiple={false}
                    item
                    images={props.uploadImages !== undefined
                    ? props.uploadImages
                    : []}
                    accept="image/*, .doc, .docx, .txt, .pdf "
                    browse="(jpg, png, jpeg, pdf, doc, docx)"
                    onFiles={imagesHandler}/>
            </div>

        </div>
    )
}

export default FormOne