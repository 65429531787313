import  {useContext} from 'react';

import {useNavigate} from 'react-router-dom';
import { usePaystackPayment } from 'react-paystack';


import {useHttpClient} from '../../hooks/dashboard-http-hook';
import { AuthContext } from '../../../../context/auth-context';




const PaystackButton = (props) => {
    const history = useNavigate();
    
    const auth = useContext(AuthContext);

    const { error, sendRequest} = useHttpClient();


    const verify = async (reference) => {
            
        try {
            await sendRequest(`${process.env.REACT_APP_API_CALL}/customers/insurance/buy/verify/${reference}`, "GET",null, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer '+auth.token
            });
            
            history('../payments')

        } catch (err) {
            console.log(error)
        }
    }

    const config = {
        reference: props.reference,
        email: props.email,
        amount: props.amount *100,
        publicKey: `${process.env.REACT_APP_PAYSTACK_PUBLIC_KEY}`,
    };

    // you can call this function anything
    const onSuccess = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.
        
        
        verify(reference.reference);

    };

    // you can call this function anything
    const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        console.log('closed')
    
    }


    const initializePayment = usePaystackPayment(config);

    const runThings = () =>{
        initializePayment(onSuccess, onClose);
    }
    
    return (
        <div className='flex__center mt-8'>
            <button className='px-8 py-2 bg-primary-red shadow-md text-white' onClick={() => {
                runThings()
                
            }} 
            ref={props.paystackClick}></button>
        </div>
    );
};

export default PaystackButton