import React from 'react'

function Cover() {
  return (
    <div className='h-75vh w-screen relative pages-cover__background'>
        <div className='absolute top__20 p-8 md:pl-16 xl:pl-32 text-white'>
            <h1 
            className='mb-8 text-2xl sm:text-3xl  line-height-1 md:text-3xl lg:text-4xl xl:text-5xl w-3/6 font-bold heading__primary'>
                <span className='bg-primary-red text-justify py-2'>We are the best brokers in Nigeri</span>a
            </h1>
              
        </div>
    </div>
  )
}

export default Cover